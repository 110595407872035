import React from "react";
import styled from "styled-components";
import {
  useProductDetail,
  useProductDetailAction,
} from "../../providers/ProductDetailProvider";
import { getThousandCommaPrice, renderPrice } from "../../utils/utils";
import Button from "../elements/Button";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart-active-icon.svg";
import { ReactComponent as HeartEmptyIcon } from "../../assets/icons/heart-empty2-icon.svg";
import useUser from "../../hooks/useUser";

interface Props {
  onBuy?: () => void;
  onSale?: () => void;
}

function ProductDetailFooter({ onBuy, onSale }: Props) {
  const data = useProductDetail();
  const { toggleLike } = useProductDetailAction();
  const { checkLogin } = useUser();

  const handleClickLike = checkLogin(() => {
    toggleLike(data.card.id);
  });

  return (
    <Wrapper>
      <div className="container">
        <div className="heart-wrapper">
          {data.card.my_like === 1 ? (
            <HeartIcon className="heart-icon" onClick={handleClickLike} />
          ) : (
            <HeartEmptyIcon className="heart-icon" onClick={handleClickLike} />
          )}
        </div>
        <div className="buttons">
          <Button direction="column" onClick={onSale}>
            <p>판매하기</p>
            <p className="button-text">
              {renderPrice(data?.high_card_stock?.price)}
            </p>
          </Button>
          <Button styleType="sub" direction="column" onClick={onBuy}>
            <p>구매하기</p>
            <p className="button-text sub">
              {renderPrice(data?.low_card_stock?.price)}
            </p>
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 10;

  & .container {
    width: 100%;
    max-width: 840px;
    margin: 0 auto;

    padding: 10px 20px;

    width: 100%;
    display: flex;
    gap: 15px;
  }

  & .heart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .heart-icon {
    width: 28px;
    height: 28px;
  }

  & .buttons {
    flex: 1;
    display: flex;
    gap: 5px;
  }

  & .button-text {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(243, 243, 243, 1);
  }

  & .button-text.sub {
    color: rgba(58, 58, 58, 1);
  }
`;

export default ProductDetailFooter;
