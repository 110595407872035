import React from "react";
import styled from "styled-components";
import OpenCardImage from "../../assets/images/open-card-image.png";
import ShadowImage from "../../assets/images/shadow-image.png";
import OpenCardGif from "../../assets/images/open-card-gif.gif";

interface OpenImageProps {}

function OpenImage() {
  return (
    <Wrapper>
        <>
        <img src={OpenCardGif} alt={"card_gif"} className="open-card-gif"/>
        </>
{/*      <div className="title mt-60 center">디지털 카드 팩 개봉중…</div>
      <div className="center column card-wrapper">
        <img src={OpenCardImage} alt="card_image" className="open-card-image" />
        <img src={ShadowImage} alt="shadow_image" className="shadow-image" />
      </div>*/}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  background: linear-gradient(
    200.77deg,
    rgba(0, 0, 0, 0.75) 3.8%,
    #777da5 114.29%
  );
    
  & .open-card-gif{
    width: 100%;
    height: 100%;
    object-fit: fill;  
  }
    
  & .title {
    font-family: Noto Sans KR;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  @keyframes up-down {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-30px);
    }

    to {
      transform: translateY(0px);
    }
  }

  @keyframes scale {
    0% {
      transform: scaleX(100%);
    }

    50% {
      transform: scaleX(70%);
    }

    to {
      transform: scaleX(100%);
    }
  }

  & .open-card-image {
    width: 200px;
    animation: up-down 3s ease-in infinite;
  }

  & .card-wrapper {
    margin-top: 67px;
  }

  & .shadow-image {
    animation: scale 3s ease-in infinite;
  }
`;

export default OpenImage;
