import { api } from "../utils/api";
import { isMobile } from "../utils/utils";

export interface OrderVariables {
  card_id?: string;
  card_amount?: number | string;
  card_stock_ids?: string[];
  card_amounts?: Record<string, string>;
  is_promotion?: 1 | 0;
  is_promotion_time_deal?: 1 | 0;
  promotion_card_id?: string;
  is_digital?: 1 | 0;
  save_address?: 1 | 0;
  address_name?: string;
  address_phone?: string;
  address?: string;
  address_detail?: string;
  address_request?: string;
  user_coupon_ids?: Record<string, string>;
  point?: string;
  money?: string;
  payment_method?: "CARD" | "VBANK";
  refund_bank?: string;
  refund_account?: string;
  refund_name?: string;
  cart_ids?: string[];
  promotion_id?: string;
  gift?: number;

}

export const orderSaveMutation = async (payload: OrderVariables) => {
  return api.post("/order", payload);
};

export const exchangeOrderSaveMutation = async (payload: any) => {
  return api.post("/exchange/order", payload);
};

export const orderMutation = async (orderId: string) => {
  const token = localStorage.getItem("token");

  if (!token) {
    window.alert("토큰이 없습니다.");
    return;
  }

  window.location.href = `${
    process.env.REACT_APP_API_URL
  }/order/payment?order_id=${orderId}&access_token=${token.trim()}&module_type=${
    isMobile() ? "mobile" : "pc"
  }`;
};

export const exchangeOrderMutation = async (exchangeId: string) => {
  const token = localStorage.getItem("token");

  if (!token) {
    window.alert("토큰이 없습니다.");
    return;
  }

  window.location.href = `${
    process.env.REACT_APP_API_URL
  }/exchange/payment?exchange_id=${exchangeId}&access_token=${token.trim()}&module_type=${
    isMobile() ? "mobile" : "pc"
  }`;
};
