import React, { useState } from "react";
import styled from "styled-components";
import Title from "../../components/common/Title";
import ImageFile from "../../components/elements/ImageFile";
import { ImageType } from "../../types/types";

interface Props {
  images: ImageType[];
  onChange: (images: ImageType[]) => void;
  onRemove?: (image: ImageType) => void;
}

function RegisterCardImageContainer({ images, onChange, onRemove }: Props) {
  return (
    <Wrapper>
      <Title title="카드 이미지 등록" />
      <div className="description-wrapper">
        <p className="description">
          현재 소지한 카드 이미지(상태)를 업로드 해주세요.
        </p>
        <p className="description">(앞면/뒷면 필수) 건당 6MB 이하, 최대 5건</p>
        <p className="description">
          하자 부분 체크시 해당부분 상세하게 업로드 바랍니다.
        </p>
      </div>

      <div className="mt-20">
        <ImageFile
          image={images}
          setImage={onChange}
          handleRemove={onRemove}
          size={6}
          max={5}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .description-wrapper {
    margin-top: 10px;
  }

  & .description {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }
`;

export default RegisterCardImageContainer;
