import React, {useState} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../elements/Button";
import { BackWrapperType } from "../../types/types";
import Menu from "./Menu";
import { handleShare } from "../../utils/CommonFunction";
import { useSetRecoilState } from "recoil";
import { toastModalState } from "../../utils/atom";

import { ReactComponent as Back } from "../../assets/icons/back-icon.svg";
import { ReactComponent as Share } from "../../assets/icons/share-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon.svg";
import { ReactComponent as TootipIcon } from "../../assets/icons/tooltip.svg";
import { ReactComponent as PointGold } from "../../assets/icons/point-gold-icon.svg";
import { ReactComponent as CoinGreen } from "../../assets/icons/coin-green-icon.svg";
import { ReactComponent as CoinChangeOn } from "../../assets/icons/coin-change-on-icon.svg";
import { ReactComponent as CoinChangeOff } from "../../assets/icons/coin-change-off-icon.svg";

import OutsideClickHandler from "react-outside-click-handler";

const BackWrapper = ({
  share,
  gnb,
  children,
  title,
  titleButton,
  buttonText,
  buttonStyle,
  disable,
  onTitle,
  onClick,
  onBack,
  right,
  explain,
  handleSaveClick,
  textType,
}: BackWrapperType) => {
  const navigate = useNavigate();
  const setToastModal = useSetRecoilState(toastModalState);
  const [open, setOpen] = useState(false);

  const handleToast = () => {
    setToastModal({
      text: "링크가 복사되었어요!",
      high: buttonText && onClick ? true : false,
    });
  };
  const togglePopover = (toggle: boolean) => () => {
    setOpen(toggle);
  };

  return (
    <Wrapper>
      <Header className={titleButton ? "titleButton" : ""}>
        <span>
          <BackIcon onClick={() => (onBack ? onBack() : navigate(-1))}/>
        </span>
        <div className="title-wrapper">
          <div >
            <h3 className="title">{title}</h3>
          </div>
          {explain && (
              <InfoIcon className="info-icon" onClick={togglePopover(true)}/>
          )}

          {explain && open && (
              <OutsideClickHandler onOutsideClick={togglePopover(false)}>
                <div className="explain">
                  <TootipIcon className="tooltip-icon"/>
                  <b>{explain.title}</b>
                  <br/><p>{explain.text}</p>
                  {explain.text2 && <p>{explain.text2}</p>}
                  {explain.text3 && <p>{explain.text3}</p>}
                  {explain.text4 && <><br/><p>{explain.text4}</p></>}
                </div>
              </OutsideClickHandler>
          )}
        </div>
        {titleButton ? (
            <button type="button" onClick={onTitle}>
              {titleButton}
            </button>
        ) : share ? (
            <ShareButtonArea>
              <Share onClick={() => handleShare(handleToast)}/>
            </ShareButtonArea>
        ) : right ? (
            <p className="save" onClick={handleSaveClick}>
              {right}
            </p>
        ) : (
            <span/>
        )}
      </Header>
      <Content
        className={
          (buttonText && onClick ? "button" : "") + (gnb ? " gnb" : "")
        }
      >
        {children}
      </Content>
      {gnb && <Menu />}
      {buttonText && onClick && (
        <BottomArea>
          <Button
            onClick={onClick}
            styleType={buttonStyle && buttonStyle}
            disabled={disable}
          >
            {textType == 'point' && disable
                ? (<><CoinChangeOff/>&nbsp;{buttonText}</>)
                : textType == 'point' && !disable
                    ? (<><CoinChangeOn/><p style={{color:"black"}}>&nbsp;{buttonText}</p></>)
                :
                buttonText
            }
          </Button>
        </BottomArea>
      )}
    </Wrapper>
  );
};

export default BackWrapper;

const ShareButtonArea = styled.div`
  display: flex;
  align-items: center;
`;

const BottomArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: ${(props) => props.theme.color.white};
  width: 100%;
  padding: 20px;
`;

const BackIcon = styled(Back)`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray3};

  & .title {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(93, 93, 93, 1);
  }

  & .title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  & > span {
    width: 24px;
  }

  & > svg {
    margin-right: 2px;
  }

  & > button {
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.2px;
    text-decoration-line: underline;
    color: ${(props) => props.theme.color.gray3};
  }
  & p.save {
    color: #b8dd22;
    cursor: pointer;
  }
  &.titleButton {
    & > span {
      width: 60px;
    }
  }

  & .info-icon {
    margin-left: 4px;
  }

  & .explain {
    position: absolute;
    top: 50px;
    left: -25px;

    transform: translateX(-25%);

    width: calc(250%);

    padding: 10px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    //gap: 10px;
    z-index: 1;
    
    background: rgba(94, 94, 94, 0.9);

    color: #fff;

    & b {
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }

    & p {
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  & .tooltip-icon {
    position: absolute;
    top: -10px;
    left: 135px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  overflow: auto;

  &.button {
    padding-bottom: 88px;
  }

  &.gnb {
    height: calc(100% - 114px);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
