import React from "react";
import styled, { css } from "styled-components";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | React.ReactNode[];
  size?: "sm" | "md";
  disabled?: boolean;
  styleType?: "circle" | "gray" | "line" | "sub" | "coin";
  direction?: "column" | "row";
};

const Button = ({
  children,
  size = "md",
  disabled,
  styleType,
  direction,
  ...props
}: ButtonProps) => {
  return (
    <Wrapper
      className={styleType}
      size={size}
      disabled={disabled}
      direction={direction}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.button<{
  size: "sm" | "md";
  disabled?: boolean;
  direction?: "column" | "row";
}>`
  //크기 중간
  width: 100%;
  height: 48px;

  //위치
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => props.direction};

  //기본
  font-weight: 700;
  font-size: 14px;
  line-height: 1.38;
  color: ${(props) => props.theme.color.white};
  border-radius: 10px;
  background: ${(props) => props.theme.color.gray2};
  border: solid 1px ${(props) => props.theme.color.gray2};

  //선
  &.line {
    color: ${(props) => props.theme.color.gray2};
    background: ${(props) => props.theme.color.white};
    border-color: ${(props) => props.theme.color.gray4};
  }

  &.sub {
    color: ${(props) => props.theme.color.gray2};
    background: ${(props) => props.theme.color.sub};
    border-color: ${(props) => props.theme.color.sub};
  }

  //원형
  &.circle {
    border-radius: 100px;
  }

  &.gray {
    color: ${(props) => props.theme.color.gray2};
    background: ${(props) => props.theme.color.gray8};
    border-color: ${(props) => props.theme.color.gray8};
  }

  &.coin {
    color: ${(props) => props.theme.color.black};
    background: #D4F648;
    border-color: #D4F648;
  }
  
  &:disabled {
    color: ${(props) => props.theme.color.white};
    background: ${(props) => props.theme.color.gray6};
    border-color: ${(props) => props.theme.color.gray6};

    cursor: not-allowed;
  }

  //이미지
  & > img {
    width: 19px;
    height: 19px;
  }

  ${(props) =>
    props.size === "sm" &&
    css`
      height: 42px;
      font-weight: 500;

      &.line {
        color: ${(props) => props.theme.color.gray3};
      }
    `}
`;
