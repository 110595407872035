import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";

interface ProductPriceInfoProps {
  price: number;
  amount: {
    total: number;
    open: number;
    remain: number;
  };

  className?: string;
}

function ProductPriceInfo({ price, amount, className }: ProductPriceInfoProps) {
  return (
    <Wrapper className={className ? className : ""}>
      <div className="box">
        <label className="label">판매가</label>
        <p className="value">{getThousandCommaPrice(price)}원</p>
      </div>
      <div className="box">
        <label className="label">개봉율</label>
        <p className="value">
          {amount.open}/{amount.total}
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;

  & .box {
    flex: 1;
  }

  & .label {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;

    color: rgba(172, 172, 172, 1);
  }

  & .value {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
    margin-top: 6px;
  }
`;

export default ProductPriceInfo;
