import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon.svg";
import { ReactComponent as TootipIcon } from "../../assets/icons/tooltip.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import OutsideClickHandler from "react-outside-click-handler";

interface CommonHeaderProps {
  title: string;
  subTitle?: string;
  onClickArrow?: () => void;
  explain?: {
    title: string;
    text: string;
  };

  isCloseIcon?: {
    onClickClose?: () => void;
  };
}

function CommonHeader({
  title,
  onClickArrow,
  explain,
  isCloseIcon,
  subTitle,
}: CommonHeaderProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickBackArrow = () => {
    if (onClickArrow) {
      onClickArrow();
    } else {
      navigate(-1);
    }
  };

  const togglePopover = (toggle: boolean) => () => {
    setOpen(toggle);
  };

  return (
    <Wrapper>
      {!isCloseIcon && (
        <div className="left-arrow" onClick={handleClickBackArrow}>
          <BackIcon />
        </div>
      )}

      {isCloseIcon && (
        <div className="close-icon" onClick={isCloseIcon?.onClickClose}>
          <CloseIcon />
        </div>
      )}

      <div className="title-wrapper">
        <div>
          {subTitle && <h4 className="sub-title">{subTitle}</h4>}
          <h3 className="title">{title}</h3>
        </div>
        {explain && (
          <InfoIcon className="info-icon" onClick={togglePopover(true)} />
        )}

        {explain && open && (
          <OutsideClickHandler onOutsideClick={togglePopover(false)}>
            <div className="explain">
              <TootipIcon className="tooltip-icon" />
              <b>{explain.title}</b>
              <p>{explain.text}</p>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 54px;

  display: flex;
  align-items: center;
  justify-content: center;

  & .left-arrow {
    position: absolute;
    left: 20px;
  }

  & .close-icon {
    position: absolute;
    right: 20px;
  }

  & .title {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(93, 93, 93, 1);
  }

  & .sub-title {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(93, 93, 93, 1);
    margin-bottom: 4px;
  }

  & .title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }

  & .info-icon {
    margin-left: 4px;
  }

  & .explain {
    position: absolute;
    top: 50px;
    left: -25px;

    transform: translateX(-25%);

    width: calc(200%);

    padding: 10px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1;

    background: rgba(94, 94, 94, 0.9);

    color: #fff;

    & b {
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }

    & p {
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  & .tooltip-icon {
    position: absolute;
    top: -10px;
    left: 135px;
  }
`;

export default CommonHeader;
