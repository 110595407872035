import React from "react";
import { useRecoilValue } from "recoil";
import BuyDigitalModal from "../../components/modal/card/BuyDigitalModal";
import BuyDigitalPackModal from "../../components/modal/card/BuyDigitalPackModal";
import BuyGoodsModal from "../../components/modal/card/BuyGoodsModal";
import ImageModal from "../../components/modal/ImageModal";
import WaybillModal from "../../components/modal/WaybillModal";
import {
  buyDigitalModalState,
  buyDigitalPackModalState,
  buyGoodsModalState,
  imageModalState,
  waybillModalState,
} from "../../recoil/atoms/modal";

function BaesCore() {
  const waybillModal = useRecoilValue(waybillModalState);
  const buyGoodsModal = useRecoilValue(buyGoodsModalState);
  const buyDigitalModal = useRecoilValue(buyDigitalModalState);
  const buyDigitalPackModal = useRecoilValue(buyDigitalPackModalState);
  const imageModal = useRecoilValue(imageModalState);
  return (
    <>
      {imageModal !== null && <ImageModal />}
      {waybillModal !== null && <WaybillModal />}
      {buyGoodsModal !== null && <BuyGoodsModal />}
      {buyDigitalModal !== null && <BuyDigitalModal />}
      {buyDigitalPackModal !== null && <BuyDigitalPackModal />}
    </>
  );
}

export default BaesCore;
