import React, { useEffect } from "react";
import {useResetRecoilState, useSetRecoilState} from "recoil";
import styled from "styled-components";
import DigitalPacakgeFooter from "../../components/detail/DigitalPacakgeFooter";
import ProductDetailHeader from "../../components/detail/ProductDetailHeader";
import Core from "../../containers/base/Core";
import DigitalPackageContainer from "../../containers/detail/DigitalPackageContainer";
import { digitalPackagePaymentModalState } from "../../recoil/atoms/modal";
import auth from "../../utils/auth";
import {useNavigate} from "react-router-dom";
import {twoButtonModalState} from "../../utils/atom";

function DigitalPackageDetail() {

    const navigate = useNavigate();

    const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

    const reset = useResetRecoilState(digitalPackagePaymentModalState);

  // 페이지 나갔을 때 digitalPackagePaymentModal 상태값 리셋
  useEffect(() => {
    return () => {
      reset();
    };
  });

    if (!auth.getToken()) {
        setTwoButtonModal({
            title: "로그인 필요",
            text: "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
            onConfirm: () => navigate("/"),
            onClose: () => history.back()
        });
        return (<></>);
    }

  return (
    <>
      <Wrapper>
        <ProductDetailHeader />
        <DigitalPackageContainer />
        <DigitalPacakgeFooter />
      </Wrapper>
      <Core />
    </>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
`;

export default DigitalPackageDetail;
