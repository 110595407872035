import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { profileModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

import { ReactComponent as Menu1 } from "../../assets/icons/profile-input-icon.svg";
import { ReactComponent as Menu2 } from "../../assets/icons/profile-remove-icon.svg";

const ProfileModal = () => {
  const [modal, setModal] = useRecoilState(profileModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    handleClose();
    modal?.handleProfile(e);
  };

  const handleRemove = async () => {
    handleClose();
    modal?.handleRemoveProfile();
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          <List>
            <Menu1 />
            프로필 이미지 설정
            <input type="file" onChange={handleImage} accept="image/*" />
          </List>
          <List onClick={handleRemove}>
            <Menu2 />
            프로필 이미지 삭제
          </List>
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ProfileModal;

const List = styled.label`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  color: ${(props) => props.theme.color.gray2};
  cursor: pointer;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  & > input {
    display: none;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding-top: 20px;
  overflow: auto;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
