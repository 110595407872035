import React from "react";
import styled from "styled-components";
import { CommunityType } from "../../types/product";

interface CommunityCardProps {
  community: CommunityType;
}

function CommunityCard({ community }: CommunityCardProps) {
  return (
    <Wrapper>
      <img src={community.thumbnail_image_url} />
      <div className="name">{community.title}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & img {
    width: 202px;
    height: 126px;
    border-radius: 10px;
  }

  & .name {
    margin-top: 8px;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export default CommunityCard;
