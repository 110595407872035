import React from "react";
import styled from "styled-components";
import StockUserProfile from "../payment/StockUserProfile";
import TifIcon from "../../assets/icons/tif-icon.svg";
import { getThousandCommaPrice } from "../../utils/utils";

interface PromotionCardProps {
  promotion: {
    id: number;
    name: string;
    order_number: string;
    price: number;
    thumbnail_image_url: string;
  };
}

function PromotionCard({ promotion }: PromotionCardProps) {
  return (
    <Wrapper>
      <StockUserProfile type="promotion" profileUrl={TifIcon} nickname="티프" />

      <div className="info-wrapper">
        <div className="name">{promotion.name}</div>
        <div className="price">{getThousandCommaPrice(promotion.price)}원</div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .name {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }

  & .price {
    margin-top: 7px;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }

  & .info-wrapper {
    padding: 16px 0;
  }
`;

export default PromotionCard;
