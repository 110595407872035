import React from "react";
import styled from "styled-components";
import { Dot } from "../../utils/CommonFunction";
import { CartListType } from "../../types/types";
import { api } from "../../utils/api";
import { useSetRecoilState } from "recoil";
import { twoButtonModalState } from "../../utils/atom";
import auth from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckAct } from "../../assets/icons/check-active-icon.svg";
import { ReactComponent as Check } from "../../assets/icons/check-icon.svg";

import { ReactComponent as Heart } from "../../assets/icons/heart-empty-icon.svg";
import { ReactComponent as HeartActive } from "../../assets/icons/heart-active-icon.svg";
import { customToast } from "../../utils/utils";

const CardList = ({
  id,
  type,
  image,
  my_like,
  title,
  price,
  is_digital,
  category_name,
  onClick,
  label,
  onLike,
  check,
  checked,
}: CartListType) => {
  const navigate = useNavigate();
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

  const handleLike = async (e: any) => {
    if (!auth.getToken())
      setTwoButtonModal({
        title: "로그인 필요",
        text: "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
        onConfirm: () => navigate("/"),
      });
    else {
      const formData = new FormData();
      formData.append("card_id", String(id));

      const {
        data: { success, alert, data },
      } = await api.post("/card/like", formData);

      if (success) {
        if (my_like === 1) {
        } else {
          customToast(`관심 상품 목록에 추가되었어요!`);
        }
      } else {
        window.alert(alert);
      }
      onLike?.(id);
    }
  };

  return (
    <Wrapper className={type}>
      <ImageBox>
        <div onClick={onClick}>
          {is_digital === 1 && <Digital>디지털</Digital>}
          {check &&
            (checked ? (
              <CheckAct className="check" onClick={onClick} />
            ) : (
              <Check className="check" onClick={onClick} />
            ))}
          <img src={image} alt="" />
        </div>
        {my_like === 1 ? (
          <HeartActive onClick={handleLike} />
        ) : (
          <Heart onClick={handleLike} />
        )}
      </ImageBox>
      <TextBox onClick={onClick}>
        {category_name && <h1>{category_name}</h1>}
        <p style={{lineHeight:"16px"}}>{title}</p>
        {price ? <h1>{Dot(price)} 원</h1> : <h1>-</h1>}
        <span>{label ?? "즉시 구매가"}</span>
      </TextBox>
    </Wrapper>
  );
};

export default CardList;

const Digital = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 7px 6px;
  border-radius: 6px;
  background: ${(props) => props.theme.color.sub};
  font-size: 11px;
  font-weight: 700;
  color: ${(props) => props.theme.color.gray1};
`;

const TextBox = styled.div`
  & > p {
    margin-top: 12px;
    font-size: 13px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray2};
  }

  & > h1 {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray1};
  }

  & > h2 {
    font-size: 12px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray3};
  }
  
  & > span {
    margin-top: 6px;
    font-size: 10px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray4};
  }
`;

const ImageBox = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 14px;
  background: ${(props) => props.theme.color.gray8};

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 93px;
      height: 130.5px;
      object-fit: contain;
    }
  }

  & > svg {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
  & svg.check {
    position: absolute;
    top: 14px;
    left: 14px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
  .pick & {
    background: ${(props) => props.theme.color.white};
    border: 1px solid ${(props) => props.theme.color.gray7};
  }
`;

const Wrapper = styled.div`
  width: calc(50% - 6px);
  margin-right: 12px;
  cursor: pointer;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 3) {
    margin-top: 24px;
  }
`;
