import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";

interface PaymentInfoProps {
  price: number;
  deliveryPrice: number;
  className?: string;
}

function PaymentInfo({ price, deliveryPrice, className }: PaymentInfoProps) {
  return (
    <Wrapper className={className ? className : ""}>
      <div className="box">
        <label className="label">상품 금액</label>
        <span className="value">{getThousandCommaPrice(price)}원</span>
      </div>
      <div className="box mt-10">
        <label className="label">배송비</label>
        <span className="value">{getThousandCommaPrice(deliveryPrice)}원</span>
      </div>
      <div className="box mt-18">
        <label className="label bold">결제금액</label>
        <span className="value bold">
          {getThousandCommaPrice(price + deliveryPrice)}원
        </span>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .label {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }

  & .value {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(93, 93, 93, 1);
  }

  & .label.bold {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .value.bold {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(30, 30, 30, 1);
  }
`;

export default PaymentInfo;
