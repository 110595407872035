import React, {useMemo, useState} from "react";
import styled from "styled-components";
import { Percent } from "../../utils/CommonFunction";
import {convertListVariables, getPercent, getThousandCommaPrice} from "../../utils/utils";
import { ReactComponent as UpIcon } from "../../assets/icons/up-icon.svg";
import { ReactComponent as DownIcon } from "../../assets/icons/down-icon.svg";
import {CARD_GRADING_VARIABLES} from "../../utils/variables";
interface ProductRecentPriceProps {
  recentPrice: number | null;
  recentGap: number | null;
  lowCardStock: number | null;
  highCardStock: number | null;
  lowCardStockGradings?: any[];
  is_pack: number;
  is_digital: number;
}

function ProductRecentPrice({
  recentGap,
  recentPrice,
  lowCardStock,
  highCardStock,
  lowCardStockGradings,
  is_pack,
  is_digital
}: ProductRecentPriceProps) {
  const upDownType = useMemo(
    () => ((recentGap || 0) >= 0 ? "up" : "down"),
    [recentGap]
  );

  const [grading, setGrading] = useState<string>("");

  const originPrice = useMemo(() => {
    return (recentPrice || 0) - (recentGap || 0);
  }, [recentGap, recentPrice]);

  const percent = useMemo(
    () => getPercent(recentPrice, originPrice),
    [recentGap, originPrice]
  );

  const price = useMemo(() => {
        let arr = [];
        if(is_digital == 0 && is_pack == 0) {
            if (lowCardStock && grading == "") {
                return getThousandCommaPrice(lowCardStock) + '원 ~';
            } else if (lowCardStockGradings) {
                arr = lowCardStockGradings?.filter((price: any) => price.card_grading == grading);
            }

            if (arr[0]) {
                return getThousandCommaPrice(arr[0].price) + '원 ~';
            }
            return '-';
        }else {
            if(lowCardStock){
                return getThousandCommaPrice(lowCardStock) + '원 ~';
            }
            return '-';
        }
      },[grading]);
  return (
      <Wrapper>
        <RadioArea>
          <button
              type="button"
              className={grading === "" ? "active" : ""}
              onClick={() => setGrading("")}
          >
            즉시 구매가
          </button>
            {is_pack == 0 && is_digital == 0 && (
              convertListVariables(CARD_GRADING_VARIABLES).map(({ value, label }) => (
                  <button
                      type="button"
                      className={grading === value ? "active" : ""}
                      onClick={() => setGrading(value)}
                  >
                    {value}
                  </button>
              ))
            )}
        </RadioArea>
{/*        <span className="label">즉시 구매가</span>*/}
        <h4 className="price">
          {price}
        </h4>
{/*        <span className="label">최근 거래가</span>
        <h4 className="price">
          {recentPrice ? `${getThousandCommaPrice(recentPrice)}원` : "-"}
        </h4>
        <p className={`gap ${upDownType}`}>
        <span className="gap-icon">
          {upDownType === "up" ? <UpIcon/> : <DownIcon/>}
        </span>
          <span className="gap-price">
          {upDownType === "up" && "+"}
            {getThousandCommaPrice(recentGap ?? 0)}원
        </span>
          <span className="gap-percent">({percent}%)</span>
        </p>*/}
      </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 28px;

  & .label {
    margin-left: 3px;
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }

  & .price {
    margin-left: 5px;
    margin-top: 6px;
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .gap {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 6px;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }

  & .up {
    color: #ff7c7c;
  }

  & .down {
    color: #66a3ff;
  }

  & .gap-icon {
    display: flex;
  }
`;

const RadioArea = styled.div`
  padding: 0 0 5px 0;

  & > button {
    padding: 4px 10px;
    border-radius: 15px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.theme.color.gray4};
    margin-right: 6px;

    &.active {
      color: ${(props) => props.theme.color.white};
      border-color: ${(props) => props.theme.color.gray1};
      background: ${(props) => props.theme.color.gray1};
    }
  }
`;

export default ProductRecentPrice;
