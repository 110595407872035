import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { paymentPointState } from "../../recoil/atoms/payment";
import { getThousandCommaPrice, onChangeNumberType } from "../../utils/utils";
import Input from "../elements/Input";
import PaymentButton from "./PaymentButton";
import useConfig from "../../hooks/useConfig";
import CommonHeader from "../common/CommonHeader";
import ExplainComponent from "../common/ExplainComponent";

interface RegisterPointProps {
  myPoint?: number;
  paymentPrice?: number;
  couponPrice?: number;
  deliveryFee?: number;
  productPrice?: number;
  moneyPrice: number;
}

function RegisterPoint({
  myPoint = 0,
  paymentPrice = 0,
  couponPrice = 0,
  deliveryFee = 0,
  moneyPrice = 0,
  productPrice = 0,
}: RegisterPointProps) {
  const [point, setPoint] = useState("");
  const [paymentPoint, setPaymentPoint] = useRecoilState(paymentPointState);
  const { data : config } = useConfig('dc_available_min_money');
  const { data : config2 } = useConfig('max_point_percent');
  const dcAvailableMinMoney = config?.config?.value;
  const maxPointPercent = config2?.config?.value;

  const isValid = useMemo(() => {
    if (productPrice < dcAvailableMinMoney) return false;
    if (!myPoint) return false;
    return true;
  }, [config, myPoint]);

  const currentPrice = useMemo(() => {
    if(productPrice + deliveryFee - couponPrice - moneyPrice <= 0){
      return 0;
    }
    if((paymentPoint + moneyPrice) > productPrice){
      return productPrice - couponPrice;
    }
    return productPrice - couponPrice - moneyPrice;
  }, [productPrice, couponPrice, moneyPrice]);

  const handleChangePoint = onChangeNumberType((value) => {
    if (Number(value) > Number(myPoint)) return;
    if (Number(value) > (currentPrice * (maxPointPercent/100))) {
      setPoint(String(Math.round(currentPrice * (maxPointPercent/100))));
      return;
    }

    setPoint(value);
  }, true);

  useEffect(() => {
    if(Number(point) > currentPrice){
      setPoint(String(Math.round(currentPrice)));
      setPaymentPoint(Math.round(currentPrice));
    }
  }, [currentPrice]);

  const handleBlur = () => {
    setPaymentPoint(Number(point));
  };

  const handleClickPaymentButton = () => {
    if (myPoint >= (currentPrice * (maxPointPercent/100))) {
      return setPaymentPoint(Math.round(currentPrice * (maxPointPercent/100)));
    }

    setPaymentPoint(Number(myPoint));
  };

  useEffect(() => {
    setPoint(String(paymentPoint));
  }, [paymentPoint]);

  return (
    <Wrapper>
      <h4 className="title">TIF COIN</h4>
      <Input
        label="TIF COIN"
        placeholder="0"
        className="mt-14 line"
        unit={
        <>
          {!isValid  && <span style={{fontSize:'12px'}}>{getThousandCommaPrice(dcAvailableMinMoney)}원부터 사용가능 </span>}
          <PaymentButton onClick={handleClickPaymentButton} disabled={!isValid}>
            모두 사용
          </PaymentButton>
          </>
        }
        subText={`보유 TIF COIN ${getThousandCommaPrice(
          (myPoint || 0) - paymentPoint
        )}원`}
        value={getThousandCommaPrice(point)}
        onChange={handleChangePoint}
        disable={!myPoint || !isValid}
        onBlur={handleBlur}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 30px;
`;

export default RegisterPoint;
