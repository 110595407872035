import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalWrapper from "./ModalWrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { imageModalState } from "../../recoil/atoms/modal";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-white-icon.svg";

function ImageModal() {
  const modal = useRecoilValue(imageModalState);
  const reset = useResetRecoilState(imageModalState);

    const [origin, setOrigin] = useState<string>("");

    useEffect(() => {
        if (modal) {
            setOrigin(location.pathname);
        }
    }, [modal]);

    useEffect(() => {
        if (origin !== "" && location.pathname !== origin) {
            reset();
        }
    }, [location.pathname]);

    if (!modal) return null;
  return (
    <ModalWrapper onClose={reset}>
      <Wrapper>
        <div className="close-icon" onClick={reset}>
          <CloseIcon />
        </div>
        <Swiper>
          {modal.images.map((image) => (
            <SwiperSlide>
              <img src={image} alt="product_images" />
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  /* background: #fff; */
  position: relative;

  & img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }

  & .close-icon {
    position: absolute;
    right: 20px;
    top: -32px;
  }
`;

export default ImageModal;
