import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { postModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import DaumPostcode from "react-daum-postcode";

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";

const PostModal = () => {
  const [modal, setModal] = useRecoilState(postModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.top = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleConfirm = () => {
    //@ts-ignore
    wrapperRef.current.style.top = "100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    const postcode = data?.zonecode; //우편번소
    const address = fullAddr; //주소

    modal?.onClick && modal.onClick(postcode, address);
    handleConfirm();
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleConfirm();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>
          우편번호 검색
          <Close onClick={handleConfirm} />
        </PopupTitle>
        <PopupText>
          <DaumPostcode
            style={{
              width: "100%",
              height: "100%",
            }}
            onComplete={onCompletePost}
          />
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default PostModal;

const PopupText = styled.div`
  width: 100%;
  height: calc(100% - 76px);
  padding: 0 20px 20px;
  margin-top: 20px;
`;

const PopupTitle = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};
  text-align: center;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 32px 32px 0 0;
  top: 100%;
  transition: all 0.3s;
`;
