import React, {useEffect} from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import {useResetRecoilState, useSetRecoilState} from "recoil";
import {digitalPackagePaymentModalState, imageModalState} from "../../recoil/atoms/modal";
import {pictureDetailModalState} from "../../utils/atom";
import {useNavigate} from "react-router-dom";

interface ProductImagesProps {
    images: { id: number; image_url: string, card_id: number}[];
    className?: string;
    width?: string;
    height?: string;
}

function ProductRelation({
                           images,
                           className,
                           width,
                           height,
                       }: ProductImagesProps) {
    const style = {
        width: width || "",
        height: height || "",
    };

    const navigate = useNavigate();
    const setImageModal = useSetRecoilState(imageModalState);

    const setPictureDetailModalState = useSetRecoilState(pictureDetailModalState);

    const reset = useResetRecoilState(pictureDetailModalState);

    useEffect(() => {
        return () => {
            reset();
        };
    });

    // 이미지 확대 모달 노출
    const handleClickImage = () => {
        setImageModal({
            images: images.map((image) => image.image_url),
        });
    };

    if(images?.length == 0){
        return (
            <></>
        )
    }
    return (
        <Wrapper>
        <div>
        <span>비슷한 상품</span>
        </div>
            <SwiperWrapper
            // @ts-ignore
            slidesPerView={"5"}
            spaceBetween={3}
            className={className ? className : ""}
            //centeredSlides={true}
            >
            {images?.map((image, i) => (
                <SwiperSlide
                    key={image.id}
                    className="slide-item"
                    //onClick={handleClickImage}
                    style={{cursor:"pointer"}}
                >
                    <img
                        style={style}
                        src={image.image_url}
                        alt="card-image"
                        className="slide-image"
                        onClick={() => {navigate(`/detail/${image.card_id}`, { replace: true })
                        }}
                    />
                </SwiperSlide>
            ))}
        </SwiperWrapper>
        </Wrapper>
    );
}

const SwiperWrapper = styled(Swiper)`
  position: relative;

  & .swiper-wrapper {
    display: flex;
  }

  & .slide-item {
    overflow-y: clip;
    position: relative;
    background: rgba(243, 243, 243, 1);
    border-radius: 15px;

    width: 100%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & img {
    width: 55.5%;
    aspect-ratio: calc(120 / 217);
    object-fit: contain;
  }

  & .slide-image {
    border-radius: inherit;
  }

  & .swiper-pagination-horizontal {
    position: absolute;

    width: 37px;
    height: 20px;
    left: calc(100% - 72px);
    bottom: 16px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.4);

    font-size: 11px;
    font-weight: 500;
    color: rgba(243, 243, 243, 1);

    display: flex;
    /* align-items: center; */
    justify-content: center;

    padding-top: 3px;
  }
`;

const Wrapper = styled.div`
    margin-top: 20px;
    width: 100%;
    & > div > span {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme.color.gray4};
    }
    
    & > div {
        margin-bottom: 10px;
    }
`;
export default ProductRelation;
