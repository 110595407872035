import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

interface MarketPriceChartProps {
  products: {
    id: number;
    created_at: string;
    product_price: number;
  }[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function MarketPriceChart({ products }: MarketPriceChartProps) {
  const data = {
    labels: products.map((product) => product.created_at),
    datasets: [
      {
        data: products.map((product) => product.product_price),
        borderColor: "rgba(30, 30, 30, 1)",
        backgroundColor: "rgba(30, 30, 30, 1)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        // beginAtZero: true,
        grid: {
          display: false,
        },
        grace: "100%",
      },
    },
  };

  return (
    <Wrapper>
      <Line data={data} options={options} />
      {products.length === 0 && (
        <EmptyText className="center">
          체결된 거래가 존재하지 않습니다.
        </EmptyText>
      )}
    </Wrapper>
  );
}

const EmptyText = styled.div`
  width: calc(100% - 36px);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(131, 131, 131, 1);

  background: rgba(243, 243, 243, 0.5);
  border-radius: 10px;
`;

const Wrapper = styled.div`
  position: relative;
  margin-top: 26px;
`;

export default MarketPriceChart;
