import React, { useEffect, useMemo } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { paymentPayloadState } from "../../recoil/atoms/payment";
import { postModalState } from "../../utils/atom";
import { onChangeStringType } from "../../utils/utils";
import CheckBox from "../elements/CheckBox";
import Input from "../elements/Input";
import PaymentButton from "./PaymentButton";

interface RegisterAddressProps {}

function RegisterAddress({}: RegisterAddressProps) {
  const setPostModal = useSetRecoilState(postModalState);
  const [payload, setPayload] = useRecoilState(paymentPayloadState);

  const isValidName = (value: string) => {
    if (value.length > 20) return false;
    return true;
  };
  const isValidPhone = (value: string) => {
    if (value.length > 11) return false;
    return true;
  };
  const isValidDetailAddress = (value: string) => {
    if (value.length > 50) return false;
    return true;
  };
  const isValidEtc = (value: string) => {
    if (value.length > 50) return false;
    return true;
  };

  const handleChangeInfo = (key: string) =>
    onChangeStringType((value) => {
      if (key === "name" && !isValidName(value)) return;
      if (key === "etc" && !isValidEtc(value)) return;
      if (key === "detailAddress" && !isValidDetailAddress(value)) return;

      setPayload((old) => ({
        ...old,
        deliveryInfo: {
          ...old.deliveryInfo,
          [key]: value,
        },
      }));
    });

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!isValidPhone(value)) return;
    if (value !== "") {
      if (isNaN(Number(value))) {
        e.target.value = value.slice(0, -1);
        return;
      }
    }

    setPayload((old) => ({
      ...old,
      deliveryInfo: {
        ...old.deliveryInfo,
        phone: value,
      },
    }));
  };

  const openAddressModal = () => {
    setPostModal({
      visible: true,
      onClick: (postCode, address) => {
        setPayload((old) => ({
          ...old,
          deliveryInfo: {
            ...old.deliveryInfo,
            postCode,
            address: address,
          },
        }));
      },
    });
  };

  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayload((old) => ({
      ...old,
      deliveryInfo: {
        ...old.deliveryInfo,
        isSave: e.target.checked,
      },
    }));
  };

  const addressValue = useMemo(() => {
    if (payload.deliveryInfo?.address || payload.deliveryInfo?.postCode) {
      return (
        (payload.deliveryInfo?.postCode || "") +
        " " +
        (payload.deliveryInfo?.address || "")
      );
    }
    return "";
  }, [payload.deliveryInfo?.address, payload.deliveryInfo?.postCode]);

  return (
    <Wrapper>
      <div className="header">
        <h4 className="title">배송 정보</h4>
        <div className="check-box">
          <CheckBox
            checked={!!payload.deliveryInfo?.isSave}
            onChange={handleChangeCheck}
          >
            <p className="check-label">배송 정보 저장</p>
          </CheckBox>
        </div>
      </div>

      <div className="inputs mt-14">
        <Input
          label="수령인명"
          placeholder="수령인명 입력"
          borderBottom="0"
          borderRadius="10px 10px 0 0"
          value={payload.deliveryInfo?.name}
          onChange={handleChangeInfo("name")}
        />
        <Input
          label="수령인 연락처"
          placeholder="수령인 연락처 입력"
          borderBottom="0"
          borderRadius="0"
          value={payload.deliveryInfo?.phone}
          onChange={handleChangePhone}
        />
        <Input
          label="우편번호"
          placeholder="우편번호 입력"
          unit={
            <PaymentButton onClick={openAddressModal}>주소 검색</PaymentButton>
          }
          borderBottom="0"
          borderRadius="0"
          value={addressValue}
          readOnly
        />
        <Input
          label="주소"
          placeholder="상세 주소"
          borderBottom="0"
          borderRadius="0"
          value={payload.deliveryInfo?.detailAddress}
          onChange={handleChangeInfo("detailAddress")}
        />
        <Input
          label="배송 요청사항"
          placeholder="배송 요청사항 입력"
          borderRadius="0 0 10px 10px"
          value={payload.deliveryInfo?.etc}
          onChange={handleChangeInfo("etc")}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 26px;

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .title {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .check-box {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }
`;

export default RegisterAddress;
