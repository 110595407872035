import React from "react";
import styled from "styled-components";
import Title from "../../components/common/Title";
import ProductExplanation from "../../components/detail/ProductExplanation";
import { useProductDetail } from "../../providers/ProductDetailProvider";

function ProductExplanationContainer() {
  const { card } = useProductDetail();

  return (
    <Wrapper>
      <Title title="상품정보" />
      <p className="sub-title">상품설명</p>
      <ProductExplanation
        explanation={card.contents}
        releaseDate={card.release_date}
        releasePrice={card.release_price}
        model={card.model}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 40px;

  & .sub-title {
    margin-top: 16px;

    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;

    color: rgba(172, 172, 172, 1);
  }
`;

export default ProductExplanationContainer;
