import { ImageType } from "../types/types";
import { api } from "../utils/api";
import {customToast} from "../utils/utils";
window.addEventListener("contextmenu", e => {
  // @ts-ignore
  e.target.matches("img") && e.preventDefault()
})
export const registerCommentMutation = async ({
  cardId,
  contents,
}: {
  cardId: string;
  contents: string;
}) => {
  return api.post("/card/comment/register", {
    card_id: cardId,
    contents,
  });
};

type ModifyCommentVariables = { commentId: number | string; contents: string };

export const modifyCommentMutation = async ({
  commentId,
  contents,
}: ModifyCommentVariables) => {
  return api.post("/card/comment/modify", {
    comment_id: commentId,
    contents,
  });
};

export const deleteCommentMutation = async (commentId: string) => {
  return api.delete("card/comment/delete", {
    params: { comment_id: commentId },
  });
};

export interface RegisterCardVariables {
  cardId: number;
  price: string;
  status: string;
  grading: string;
  level: string;
  images: ImageType[];
  contents: string;
}

export const registerCardMutation = async (payload: RegisterCardVariables) => {
  const formData = new FormData();

  formData.append("card_ids[0]", String(payload.cardId));
  formData.append(`prices[${payload.cardId}]`, String(payload.price));
  formData.append(`conditions[${payload.cardId}]`, String(payload.status));
  formData.append(`gradings[${payload.cardId}]`, String(payload.grading));
  formData.append(`levels[${payload.cardId}]`, String(payload.level));
  formData.append(`types[${payload.cardId}]`, "goods");
  formData.append(`etcs[${payload.cardId}]`, payload.contents);

  payload.images.forEach((image, index) => {
    formData.append(`images[${payload.cardId}][${index}]`, image.file);
  });

  return api.post("/card/register", formData);
};

interface RegisterDigitalCardVariables {
  cardId: string;
  stockId: string;
  price: string;
}

export const registerDigitalCardMutation = async (
  payload: RegisterDigitalCardVariables
) => {
  const { cardId, price, stockId } = payload;

  const formData = new FormData();

  formData.append(`card_ids[0]`, cardId);
  formData.append(`card_stocks[${cardId}]`, stockId);
  formData.append(`prices[${cardId}]`, String(price));
  formData.append(`types[${cardId}]`, "digital");

  return api.post("/card/register", formData);
};

interface EditDigitalCardVariables {
  stockId: string;
  price: string;
}

export const editDigitalCardMutation = async (
  payload: EditDigitalCardVariables
) => {
  const { stockId, price } = payload;

  const formData = new FormData();

  formData.append("card_stock_id", stockId);
  formData.append("price", price);

  return api.post("/card/modify", formData);
};

interface RegisterGoodsPackVariables {
  cardId: string;
  price: string;
  amount: string;
}

export const registerGoodsPackMutation = async (
  payload: RegisterGoodsPackVariables
) => {
  const formData = new FormData();

  formData.append(`card_ids[0]`, payload.cardId);
  formData.append(`prices[${payload.cardId}]`, payload.price);
  formData.append(`amounts[${payload.cardId}]`, payload.amount);
  formData.append(`types[${payload.cardId}]`, "goods");

  return api.post("/card/register", formData);
};

// 장바구니에 상품 추가 및 추가동작(callback)
export const addToCard = async (
  cardId: string | number,
  callback: Function,
  callback2?: Function,
  amount?: number,
  is_pack?: number,
) => {
  const { data } = await api.post("/cart", { card_stock_id: String(cardId), amount: String(amount)});

  if (!data.success) {
    customToast(data.alert);
    return;
  }else if(data.success == -1){
    if(is_pack == 1){
      if(callback2) {
        callback2(data.data.card_id, amount);
      }
    }else {
      customToast(data.alert);
      return;
    }
  } else {
    callback();
  }
};