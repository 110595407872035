import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useOrder from "../../hooks/useOrder";
import OpenImage from "../../components/result/OpenImage";
import CardCollection from "../../components/result/CardCollection";
import BackWrapper from "../../components/wrapper/BackWrapper";
import {api} from "../../utils/api";
import {useSetRecoilState} from "recoil";
import {alertModalState} from "../../utils/atom";
import useSWR from "swr";
import Button from "../../components/elements/Button";
import {useNavigate} from "react-router-dom";

interface DigitalPackGiftContainerProps {
    orderId: string;
}

function DigitalPackGiftContainer({orderId}: DigitalPackGiftContainerProps) {
    const [open, setOpen] = useState(false);
    const setAlertModal = useSetRecoilState(alertModalState);
    const { data } = useOrder(orderId);
    const [disable, setDisable] = useState<boolean>(true);
    const navigate = useNavigate();

    const giftType = useMemo(() => {
        if (!data) return;
        return data?.order?.gift_type;
    }, [data?.order?.gift_type]);

    const buttonText = useMemo(() => {
        if (giftType == 'kakao') {
            return "카카오톡 선물하기";
        }else {
            return "선물이 정상적으로 발송되었습니다.";
        }
    }, [data?.order]);

    const handleClick = async () => {
    };

    return (
        <Wrapper>
            <Content className="ql-snow">
                <Button disabled={disable} style={{width:"100%"}}>
                    {buttonText}
                </Button>
                <br />
                <Button onClick={() => navigate("/main")} style={{width:"100%"}}>
                    {"홈으로 돌아가기"}
                </Button>
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    height: 100%;
`;

const Content = styled.div`
    width: calc(100% - 100px);
    margin: auto;
    align-items: center;
`;

export default DigitalPackGiftContainer;
