import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { addToCard } from "../../../apis/common";
import { buyGoodsModalState } from "../../../recoil/atoms/modal";
import { api } from "../../../utils/api";
import { customToast, getThousandCommaPrice } from "../../../utils/utils";
import CardConditions from "../../card/CardConditions";
import SummaryCardImage from "../../card/SummaryCardImage";
import ModalHeader from "../../common/ModalHeader";
import Button from "../../elements/Button";
import {CommonBottomModalContainer, FixedBottomWrapper} from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import { alertModalState } from "../../../utils/atom";
import BuyGoodsContainer from "../../../containers/transaction/BuyGoodsContainer";
import CardInfo from "../../card/CardInfo";

function BuyGoodsModal() {
  const modal = useRecoilValue(buyGoodsModalState);
  const reset = useResetRecoilState(buyGoodsModalState);
  const setAlertModal = useSetRecoilState(alertModalState);
  const navigate = useNavigate();

  const handleBuy = () => {
    if (!modal) return;
    if (modal?.userId && modal?.userId === "my") {
      setAlertModal({
        title: "상품 구매 불가",
        text: "자신의 상품은 구매할 수 없습니다.",
      });
      return;
    }

    reset();

    localStorage.setItem(
      "state",
      JSON.stringify({
        type: "goods",
        stockIds: [modal.card.id],
        amounts: {
          [modal.card.id]: modal.card.amount,
        },
      })
    );

    navigate("/payment");
  };

  const handleBasket = async () => {
    if (!modal) return;

    addToCard(modal.card.id, () => {
      customToast("장바구니에 추가되었습니다!");
      reset();
    });
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      reset();
    }
  }, [location.pathname]);

  if (!modal) return null;

  return (
    <ModalWrapper position="bottom" onClose={reset}>
      <Wrapper>
        <ModalHeader title="실물 카드 구매" onClose={reset} />

        <div className="mt-10">
          <SummaryCardImage
            size="full"
            imageUrl={modal?.card?.image_url ?? ""}
            card_stock_images={modal?.card?.card_stock_images || ""}
          />
        </div>

{/*        <div className="mt-20">
          <p className="label ">상품금액</p>
          <h4 className="price mt-6">
            {getThousandCommaPrice(modal.card.price)}원
          </h4>
          <div className="mt-16">
            <CardConditions condition={modal.card.card_condition} />
          </div>
          {modal.card.etc && <div className="etc mt-10">{modal.card.etc}</div>}
        </div>*/}

        <CardInfo
            className="card-info"
            price={modal.card.price}
            contents={modal.card.etc}
            condition={String(modal.card.card_condition)}
            grading={String(modal.card.card_grading)}
            level={String(modal.card.card_level)}
            categoryName={modal.card.category_name}
            name={modal.card.name}
            subName={modal.card.sub_name}
        />

        <FixedBottomWrapper>
          <div className="footer">
            <Button styleType="line" onClick={handleBasket}>
              장바구니
            </Button>
            <Button onClick={handleBuy}>구매하기</Button>
          </div>
        </FixedBottomWrapper>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  position: relative;
  height: 90%;
  //width: 100%;
  //height: 100%;
  //background: #fff;
  overflow-y: auto;
  //padding: 0 20px;

  & .label {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }

  & .price {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  & .etc {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }

  & .footer {
    display: flex;
    gap: 8px;
    padding: 15px 20px;
  }
  
  & .card-info {
    position: absolute;
    padding-bottom: 100px;
  }
`;

export default BuyGoodsModal;
