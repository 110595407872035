import { api } from "./api";
import { isAndroid, isIOS } from "./utils";

const { parse } = JSON;

const auth = {
  clear(key: string) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    return null;
  },
  clearAppStorage() {
    return localStorage.clear();
  },
  get(key: string) {
    //@ts-ignore
    return parse(localStorage.getItem(key));
  },
  setToken(token: string) {
    if (isAndroid()) {
      // @ts-ignore
      window.tif.postMessage(JSON.stringify({ type: 'autoLogin', token: token }));
    }

    if (isIOS()) {
      // @ts-ignore
      window.webkit.messageHandlers.autoLoginHandler.postMessage(token);
    }
    localStorage.setItem("token", token);
  },
  setSocialToken(token: string, refreshToken: string) {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refreshToken);

    if (isAndroid()) {
      // @ts-ignore
      window.tif.postMessage(JSON.stringify({ type: 'autoLogin', token: token }));
    }

    if (isIOS()) {
      // @ts-ignore
      window.webkit.messageHandlers.autoLoginHandler.postMessage(token);
    }
    return;
  },
  getToken() {
    return localStorage.getItem("token");
  },
  setRefreshToken(token: string) {
    return localStorage.setItem("refreshToken", token);
  },
  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  },
  setChangePassword(pw: string) {
    return localStorage.setItem("changePassword", pw);
  },
  getChangePassword() {
    return localStorage.getItem("changePassword");
  },
  setRegisterCard(card: string) {
    return localStorage.setItem("registerCard", card);
  },
  getRegisterCard() {
    return localStorage.getItem("registerCard");
  },
};
export default auth;
