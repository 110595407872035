import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChildrenType } from "../types/types";

import Background from "../assets/images/preview-background.png";
import { ReactComponent as AppIcon } from "../assets/icons/app-icon.svg";
import { ReactComponent as Apple } from "../assets/images/apple-down.svg";
import { ReactComponent as Google } from "../assets/images/google-down.svg";
import {useNavigate} from "react-router-dom";

const Preview = ({ children }: ChildrenType) => {
  let pcDevice = "win16|win32|win64|mac|macintel";
  const navigate = useNavigate();
  const [os, setOs] = useState("pc");

  useEffect(() => {
    const varUA = navigator.userAgent.toLowerCase();
    const varPl = navigator.platform.toLowerCase();

    if (varUA.indexOf("android") > -1) {
      setOs("android");
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1
    ) {
      setOs("ios");
    } else {
      if (varPl.indexOf("mac") > -1 || varPl.indexOf("macintel") > -1) {
        setOs("ios");
      } else {
        setOs("android");
      }
    }
  }, []);

  return (
    <Wrapper>
      <Content>
        <PreviewBox
          className={
            pcDevice.indexOf(navigator.platform.toLowerCase()) >= 0
              ? "pc"
              : "mobile"
          }
        >
          <AppIcon
              onClick={() => navigate("/")}
          />
          <PreviewText
              onClick={() => navigate("/")}
          >TAKE IT FIRST!</PreviewText>
          <ButtonBox>
            <button onClick={() => window.open("https://play.google.com/store/apps/details?id=kr.tif.app&hl=ko")}>
              <Google />
            </button>
            <button onClick={() => window.open("https://apps.apple.com/kr/app/%ED%8B%B0%ED%94%84/id6471943690")}>
              <Apple />
            </button>
          </ButtonBox>
        </PreviewBox>
        <PreviewArea
          className={
            pcDevice.indexOf(navigator.platform.toLowerCase()) >= 0
              ? "pc"
              : "mobile"
          }
        >
          {children}
        </PreviewArea>
      </Content>
    </Wrapper>
  );
};

export default Preview;

const PreviewArea = styled.div`
  width: 420px;
  min-width: 420px;
  height: 100%;
  border: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.1));

  &.mobile {
    width: 100%;
    min-width: 0px;
    filter: none;
  }

  @media (max-width: 960px) {
    width: 100%;
    min-width: 0px;
    filter: none;
  }
`;

const ButtonBox = styled.div`
  & > button {
    width: 100%;

    &:nth-of-type(n + 2) {
      margin-top: 12px;
    }
  }
`;

const PreviewText = styled.p`
  margin: 25px 0 40px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.4px;
  color: ${(props) => props.theme.color.gray1};
  cursor: pointer;
`;

const PreviewBox = styled.div`
  max-width: 420px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${Background}) no-repeat center/cover;
  padding: 10px 0 77px;

  &.mobile {
    width: 100%;
    min-width: 0px;
  }

  & > svg {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

const Content = styled.div`
  max-width: 840px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;
