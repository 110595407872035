import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  Account,
  Address,
  Alarm,
  Apply,
  Attendance,
  Badge,
  Banner,
  Block,
  Buy,
  BuyDetail,
  Community,
  CommunityDetail,
  CommunitySubmit,
  Company,
  Coupon,
  DeliveryFee,
  Digital,
  DigitalDetail,
  DigitalExchange,
  Exchange,
  ExchangeReview,
  ExchangeReviewEdit,
  ExchangeDetail,
  FAQ,
  FAQDetail,
  FindId,
  FindPw,
  FindPwPhone,
  FindPwResult,
  Follow,
  GiftReceive,
  Interest,
  JoinAgree,
  JoinStep1,
  JoinStep2,
  JoinStep3,
  Like,
  Main,
  Modify,
  Money,
  MyApply,
  MyApplyDetail,
  MyComment,
  MyCommunity,
  MyProfile,
  Mypage,
  NewCard,
  NotFound,
  Notice,
  NoticeDetail,
  Password,
  Phone,
  PhoneSeller,
  PickList,
  Point,
  Profile,
  ProfileCard,
  ProfileCardDetail,
  ProfileReview,
  Promotion,
  PromotionBuyDetail,
  PromotionDetail,
  Qna,
  QnaSubmit,
  Rank,
  Review,
  ReviewEdit,
  ReviewSubmit,
  Root,
  Sale,
  SaleDelivery,
  SaleDetail,
  SaleEdit,
  SaleProduct,
  SaleProduct2,
  SavePost,
  Search,
  SearchResult,
  Setting,
  Shop,
  Supporters,
  SupportersDetail,
  SupportersSubmit,
  UseNotice,
  Withdrawal,
  ProductDetail,
  ProductCommentList,
  ProductReviewList,
  ProductRegisterComment,
  SaleUserChk,
  SaleUser,
  SaleUser2,
  SaleUserCompany2,
  SaleUserCompany3,
  Cart,
  SaleGoods,
  SaleDigital,
  ProductDetailRoute,
  BuyGoods,
  BuyDigital,
  BuyGoodsList,
  BuyDigitalList,
  BuyGoodsPack,
  SaleGoodsPack,
  CheckWaybill,
  PaymentExchange,
  PaymentRequest,
  UserShop,
  MyReview,
  SalePackEdit,
  Social,
  SaleGoodsList,
  Terms,
  TIFNotice,
  DdpReceive,
  TIFNoticeDetail,
  SellerUser,
  DigitalPayback,
} from "../pages";
import { SWRConfig } from "swr";
import swrConfig from "../utils/swrConfig";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../styles/global-styles";
import theme from "../utils/theme";
import WebRoute from "../components/routes/WebRoute";
import HelmetComponent from "../components/HelmetComponent";
import auth from "../utils/auth";
import PrivateRoute from "../components/routes/PrivateRoute";
import PaymentRoute from "../pages/payment/PaymentRoute";
import { Toaster } from "react-hot-toast";
import PaymentResult from "../pages/payment/result/PaymentResult";
import ProductDetailPage from "../pages/detail/ProductDetailPage";
import { initialize } from "../utils/init";

const App = () => {
  useEffect(() => {
    auth.clear("historyLength");
    checkForAccess();

    window.localStorage.removeItem("appstart");
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  const checkForAccess = () => {
    const varUA = navigator.userAgent.toLowerCase();

    if (auth.getToken() !== null) {
      try {
        if (varUA.indexOf("android") > -1) {
          //@ts-ignore
          window.tif.postMessage(
            JSON.stringify({ type: "autoLogin", token: auth.getToken() })
          );
        } else if (
          varUA.indexOf("iphone") > -1 ||
          varUA.indexOf("ipad") > -1 ||
          varUA.indexOf("ipod") > -1
        ) {
          //@ts-ignore
          window.webkit.messageHandlers.autoLoginHandler.postMessage(
            auth.getToken()
          );
        }
      } catch (error) {}
    }
  };

  return (
    <SWRConfig
      value={{
        ...swrConfig,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }}
    >
      <ThemeProvider theme={theme}>
        <HelmetComponent />
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<WebRoute element={<Root />} />} />
          <Route
            path="/joinAgree"
            element={<WebRoute element={<JoinAgree />} />}
          />
          <Route
            path="/terms/:type"
            element={<WebRoute element={<Terms />} />}
          />
          <Route
            path="/join_step1"
            element={<WebRoute element={<JoinStep1 />} />}
          />
          <Route
            path="/join_step2/:type"
            element={<WebRoute element={<JoinStep2 />} />}
          />
          <Route
            path="/join_step3/:type"
            element={<WebRoute element={<JoinStep3 />} />}
          />
          <Route path="/find_id" element={<WebRoute element={<FindId />} />} />
          <Route path="/find_pw" element={<WebRoute element={<FindPw />} />} />
          <Route
            path="find_pw/phone"
            element={<WebRoute element={<FindPwPhone />} />}
          />
          <Route
            path="/new_pw"
            element={<WebRoute element={<FindPwResult />} />}
          />
          <Route
            path="/user/submit/sale/chk"
            element={<PrivateRoute element={<SaleUserChk />} />}
          />
          <Route
            path="/user/submit/sale"
            element={<PrivateRoute element={<SaleUser />} />}
          />
          <Route
            path="/user/submit/sale2"
            element={<PrivateRoute element={<SaleUser2 />} />}
          />
          <Route
            path="/user/submit/sale2/company"
            element={<PrivateRoute element={<SaleUserCompany2 />} />}
          />
          <Route
            path="/user/submit/sale3/company"
            element={<PrivateRoute element={<SaleUserCompany3 />} />}
          />

          <Route path="/social/:provider" element={<Social />} />

          {/* 장바구니 */}
          <Route path="/cart" element={<PrivateRoute element={<Cart />} />} />

          {/* 메인 */}
          <Route path="/main" element={<WebRoute element={<Main />} />} />
          <Route
            path="/main/pick"
            element={<WebRoute element={<PickList />} />}
          />
          <Route
            path="/main/promotion"
            element={<WebRoute element={<Promotion />} />}
          />
          <Route
            path="/main/promotion/:type/:id"
            element={<WebRoute element={<PromotionDetail />} />}
          />
          <Route
            path="/main/promotion/time_deal/:id/:card"
            element={<WebRoute element={<PromotionBuyDetail />} />}
          />
          <Route
            path="/create/apply"
            element={<PrivateRoute element={<Apply />} />}
          />
          <Route
            path="/create/modify/:id"
            element={<PrivateRoute element={<Modify />} />}
          />
          <Route
            path="/sale/goods"
            element={<PrivateRoute element={<SaleProduct />} />}
          />
          <Route
            path="/sale/digital"
            element={<PrivateRoute element={<SaleProduct2 />} />}
          />

          {/* 랭킹 */}
          <Route path="/rank/:type" element={<WebRoute element={<Rank />} />} />

          {/* 디지털 카드샵 */}
          <Route path="/shop" element={<WebRoute element={<Shop />} />} />

          {/* 커뮤니티 */}
          <Route
            path="/community/community"
            element={<WebRoute element={<Community />} />}
          />
          <Route
            path="/community/community/detail/:id"
            element={<PrivateRoute element={<CommunityDetail />} />}
          />
          <Route
            path="/community/community/submit"
            element={<PrivateRoute element={<CommunitySubmit />} />}
          />
          <Route
            path="/community/community/edit/:id"
            element={<PrivateRoute element={<CommunitySubmit />} />}
          />
          <Route
            path="/community/TIFNotice"
            element={<WebRoute element={<TIFNotice />} />}
          />
          <Route
            path="/community/TIFNotice/detail/:id"
            element={<PrivateRoute element={<TIFNoticeDetail />} />}
          />
          <Route
            path="/community/supporters"
            element={<WebRoute element={<Supporters />} />}
          />
          <Route
            path="/community/supporters/detail/:id"
            element={<PrivateRoute element={<SupportersDetail />} />}
          />
          <Route
            path="/community/supporters/submit"
            element={<PrivateRoute element={<SupportersSubmit />} />}
          />
          <Route
            path="/community/supporters/edit/:id"
            element={<PrivateRoute element={<SupportersSubmit />} />}
          />
          {/* 프로필 */}
          <Route
            path="/profile/:id"
            element={<WebRoute element={<Profile />} />}
          />
          <Route
            path="/profile/card/:id"
            element={<WebRoute element={<ProfileCard />} />}
          />
          <Route
            path="/profile/card/:id/:card"
            element={<WebRoute element={<ProfileCardDetail />} />}
          />
          <Route
            path="/profile/review/:id"
            element={<WebRoute element={<ProfileReview />} />}
          />
          {/* 마이페이지 */}
          <Route
            path="/mypage"
            element={<PrivateRoute element={<Mypage />} />}
          />
          <Route
            path="/mypage/profile"
            element={<PrivateRoute element={<MyProfile />} />}
          />
          <Route
            path="/mypage/badge"
            element={<PrivateRoute element={<Badge />} />}
          />
          <Route
            path="/mypage/interest"
            element={<PrivateRoute element={<Interest />} />}
          />
          <Route
            path="/mypage/address"
            element={<PrivateRoute element={<Address />} />}
          />
          <Route
            path="/mypage/coupon"
            element={<PrivateRoute element={<Coupon />} />}
          />
          <Route
            path="/mypage/point"
            element={<PrivateRoute element={<Point />} />}
          />
          <Route
              path="/mypage/money"
              element={<PrivateRoute element={<Money />} />}
          />
          <Route
            path="/mypage/like"
            element={<PrivateRoute element={<Like />} />}
          />
          <Route
            path="/mypage/digital"
            element={<PrivateRoute element={<Digital />} />}
          />
          <Route
            path="/mypage/digital/detail/:id"
            element={<PrivateRoute element={<DigitalDetail />} />}
          />
          <Route
            path="/mypage/digital/exchange"
            element={<PrivateRoute element={<DigitalExchange />} />}
          />
          <Route
              path="/mypage/digital/payback"
              element={<PrivateRoute element={<DigitalPayback />} />}
          />
          <Route
            path="/mypage/digital/exchange/:cardId"
            element={<PrivateRoute element={<DigitalExchange />} />}
          />
          <Route
            path="/mypage/digital/exchange/search/:value"
            element={<PrivateRoute element={<DigitalExchange />} />}
          />
          <Route
            path="/mypage/exchange"
            element={<PrivateRoute element={<Exchange />} />}
          />
          <Route
              path="/mypage/exchange/review/:id/:card"
              element={<PrivateRoute element={<ExchangeReview />} />}
          />
          <Route
              path="/mypage/exchange/review/:id"
              element={<PrivateRoute element={<ExchangeReviewEdit />} />}
          />
          <Route
            path="/mypage/exchange/:id"
            element={<PrivateRoute element={<ExchangeDetail />} />}
          />
          <Route
            path="/mypage/community"
            element={<PrivateRoute element={<MyCommunity />} />}
          />
          <Route
            path="/mypage/comment"
            element={<PrivateRoute element={<MyComment />} />}
          />
          <Route
            path="/mypage/review"
            element={<PrivateRoute element={<MyReview />} />}
          />
          <Route
            path="/mypage/follow"
            element={<PrivateRoute element={<Follow />} />}
          />
          <Route
            path="/mypage/likeCommunity"
            element={<PrivateRoute element={<SavePost />} />}
          />
          <Route
            path="/mypage/apply"
            element={<PrivateRoute element={<MyApply />} />}
          />
          <Route
            path="/mypage/apply/:id"
            element={<PrivateRoute element={<MyApplyDetail />} />}
          />
          <Route
            path="/mypage/notice"
            element={<PrivateRoute element={<Notice />} />}
          />
          <Route
            path="/mypage/notice/:id"
            element={<PrivateRoute element={<NoticeDetail />} />}
          />
          <Route path="/mypage/faq" element={<WebRoute element={<FAQ />} />} />
          <Route
            path="/mypage/faq/:id"
            element={<WebRoute element={<FAQDetail />} />}
          />
          <Route
            path="/mypage/qna"
            element={<PrivateRoute element={<Qna />} />}
          />
          <Route
            path="/mypage/qna/submit"
            element={<PrivateRoute element={<QnaSubmit />} />}
          />
          <Route
            path="/mypage/useNotice"
            element={<WebRoute element={<UseNotice />} />}
          />
          <Route
            path="/mypage/newCard"
            element={<PrivateRoute element={<NewCard />} />}
          />
          <Route
            path="/mypage/attendance"
            element={<PrivateRoute element={<Attendance />} />}
          />
          <Route
            path="/mypage/gift/receive/:number"
            element={<PrivateRoute element={<GiftReceive />} />}
          />

          <Route
              path="/mypage/ddp/receive/:cardCode"
              element={<WebRoute element={<DdpReceive />} />}
          />

          {/* 주문 내역 */}
          <Route
            path="/order/buy"
            element={<PrivateRoute element={<Buy />} />}
          />
          <Route
            path="/order/buy/:id"
            element={<PrivateRoute element={<BuyDetail />} />}
          />
          <Route
            path="/order/sale"
            element={<PrivateRoute element={<Sale />} />}
          />
          <Route
            path="/order/sale/:id"
            element={<PrivateRoute element={<SaleDetail />} />}
          />
          <Route
            path="/order/review/:order/:card"
            element={<PrivateRoute element={<ReviewSubmit />} />}
          />
          <Route
            path="/review/:id"
            element={<PrivateRoute element={<ReviewEdit />} />}
          />
          <Route
            path="/sale/edit/card/:card"
            element={<PrivateRoute element={<SaleEdit />} />}
          />
          <Route
            path="/sale/edit/pack/:card"
            element={<PrivateRoute element={<SalePackEdit />} />}
          />
          <Route
            path="/sale/edit/digital/:card"
            element={<PrivateRoute element={<SaleEdit />} />}
          />
          <Route
            path="/sale/delivery/:card"
            element={<PrivateRoute element={<SaleDelivery />} />}
          />

          {/* 설정 */}
          <Route
            path="/setting"
            element={<PrivateRoute element={<Setting />} />}
          />
          <Route
            path="/setting/block"
            element={<PrivateRoute element={<Block />} />}
          />
          <Route
            path="/setting/phone"
            element={<PrivateRoute element={<Phone />} />}
          />
          <Route
            path="/setting/phone/seller"
            element={<PrivateRoute element={<PhoneSeller />} />}
          />
          <Route
            path="/setting/password"
            element={<PrivateRoute element={<Password />} />}
          />
          <Route
              path="/setting/SellerUser"
              element={<PrivateRoute element={<SellerUser />} />}
          />
          <Route
            path="/setting/withdrawal"
            element={<PrivateRoute element={<Withdrawal />} />}
          />
          <Route
            path="/setting/account"
            element={<PrivateRoute element={<Account />} />}
          />
          <Route
            path="/setting/company"
            element={<PrivateRoute element={<Company />} />}
          />
          <Route
            path="/setting/banner"
            element={<PrivateRoute element={<Banner />} />}
          />
          <Route
            path="/setting/deliveryFee"
            element={<PrivateRoute element={<DeliveryFee />} />}
          />
          <Route
            path="/setting/review"
            element={<PrivateRoute element={<Review />} />}
          />

          {/* 알림 */}
          <Route path="/alarm" element={<PrivateRoute element={<Alarm />} />} />

          {/* 검색 */}
          <Route path="/search" element={<WebRoute element={<Search />} />} />
          <Route
            path="/search/:value"
            element={<WebRoute element={<SearchResult />} />}
          />

          {/* 카드 상세 */}
          {/* 상세페이지 */}
          <Route
            path="/detail/:cardId"
            element={<WebRoute element={<ProductDetailRoute />} />}
          >
            <Route path="" element={<ProductDetailPage />} />
            <Route path="comments" element={<ProductCommentList />} />
            <Route path="reviews" element={<ProductReviewList />} />
            <Route
              path="comment/register"
              element={<ProductRegisterComment />}
            />
            <Route
              path="comment/modify"
              element={<ProductRegisterComment mode="modify" />}
            />
          </Route>

          {/* 상품 등록,구매페이지 */}
          <Route
            path="/sale/goods/:cardId"
            element={<WebRoute element={<SaleGoods />} />}
          ></Route>

          <Route
              path="/sale/goods/list/:cardId"
              element={<WebRoute element={<SaleGoodsList />} />}
          />

          <Route
            path="/sale/digital/:cardId/:stockId"
            element={<WebRoute element={<SaleDigital />} />}
          />

          <Route
            path="/sale/digital/:cardId"
            element={<WebRoute element={<SaleDigital />} />}
          />

          <Route
            path="/sale/goods-pack/:cardId"
            element={<WebRoute element={<SaleGoodsPack />} />}
          />
          <Route
            path="/buy/goods/:cardId"
            element={<WebRoute element={<BuyGoodsList />} />}
          />
          <Route
            path="/buy/goods/:cardId/:stockId"
            element={<WebRoute element={<BuyGoods />} />}
          />
          <Route
            path="/buy/digital/:cardId"
            element={<WebRoute element={<BuyDigitalList />} />}
          />
          <Route
            path="/buy/digital/:cardId/:stockId"
            element={<WebRoute element={<BuyDigital />} />}
          />
          <Route
            path="/buy/goods-pack/:cardId"
            element={<WebRoute element={<BuyGoodsPack />} />}
          />

          {/* 결제 페이지 */}
          <Route
            path="/payment"
            element={<PrivateRoute element={<PaymentRoute />} />}
          />
          <Route
            path="/payment/result"
            element={<PrivateRoute element={<PaymentResult />} />}
          />
          <Route
            path="/payment/exchange"
            element={<PrivateRoute element={<PaymentExchange />} />}
          />
          <Route
            path="/payment/request"
            element={<PrivateRoute element={<PaymentRequest />} />}
          />

          {/* 배송 현황 확인페이지 */}
          <Route
            path="/order/check/:id/:type"
            element={<PrivateRoute element={<CheckWaybill />} />}
          />

          {/* 유저 SHOP 페이지 */}
          <Route
            path="/user/shop/:userId"
            element={<PrivateRoute element={<UserShop />} />}
          />
          <Route
            path="/user/shop/my/:name"
            element={<PrivateRoute element={<UserShop />} />}
          />
          <Route path="/error" element={<WebRoute element={<NotFound />} />} />

          {/* 404페이지 */}
          <Route
            path="/*" //@ts-ignore
            element={<WebRoute element={<NotFound />} />}
          />
        </Routes>
        <Toaster />
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
