import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import ProductDetailFooter from "../../components/detail/ProductDetailFooter";
import ProductDetailHeader from "../../components/detail/ProductDetailHeader";
import ProductDetailContainer from "../../containers/detail/ProductDetailContainer";
import useUser from "../../hooks/useUser";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import { saleDigitalDetailModalState } from "../../recoil/atoms/modal";
import { saleKindModalState } from "../../utils/atom";

function ProductDetail() {
  const { cardId } = useParams();
  const setSaleOrBuyModal = useSetRecoilState(saleKindModalState);
  const setSaleDigitalDetailmodal = useSetRecoilState(
    saleDigitalDetailModalState
  );
  const resetSaleOrBuyModal = useResetRecoilState(saleKindModalState);
  const resetSaleDigitalDetailModal = useResetRecoilState(
    saleDigitalDetailModalState
  );
  const productDetail = useProductDetail();
  const { checkAuth, checkLogin } = useUser();
  const navigate = useNavigate();

  // 판매하기 버튼 클릭
  const handleSale = checkAuth(() => {
    setSaleOrBuyModal({
      visiable: true,
      type: "sale",
      disabledGoods: productDetail.card.use_goods === 0,
      disabledDigital: !productDetail.user_digital_card_check,
      onClickGoods: () => {
        navigate(`/sale/goods/${cardId}`);
      },
      onClickDigital: () => {
        setSaleDigitalDetailmodal(true);
      },
    });
  });

  // 구매하기 버튼 클릭
  const handleBuy = checkLogin(() => {
    setSaleOrBuyModal({
      visiable: true,
      type: "buy",
      disabledGoods: productDetail.sale_goods_card_count === 0,
      disabledDigital: productDetail.sale_digital_card_count === 0,
      onClickGoods: () => {
        navigate(`/buy/goods/${cardId}`);
      },
      onClickDigital: () => {
        navigate(`/buy/digital/${cardId}`);
      },
    });
  });

  useEffect(() => {
    return () => {
      resetSaleOrBuyModal();
      resetSaleDigitalDetailModal();
    };
  }, []);

  return (
    <Wrapper>
      <ProductDetailHeader />
      <ProductDetailContainer />
      <ProductDetailFooter onBuy={handleBuy} onSale={handleSale} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
`;

export default ProductDetail;
