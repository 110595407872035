import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { saleDigitalDetailModalState } from "../../../recoil/atoms/modal";
import ModalWrapper from "../ModalWrapper";
import useSWR from "swr";
import { useNavigate, useParams } from "react-router-dom";
import CommonHeader from "../../common/CommonHeader";
import ProductSummaryInfo from "../../detail/ProductSummaryInfo";
import SelectCard, { SummaryDigitalCardType } from "../../card/SelectCard";
import PageLoadingComponent from "../../common/PageLoadingComponent";

function SaleDigitalDetailModal() {
  const [modal, setModal] = useRecoilState(saleDigitalDetailModalState);
  const { cardId } = useParams();
  const { data, isLoading } = useSWR(
    `/card/digital/detail?card_id=${cardId}&user_id=my`
  );
  const navigate = useNavigate();

  const handleClose = () => {
    setModal(false);
  };

  const handleClickCard = (card: SummaryDigitalCardType) => () => {
    if(card.card_sn == null) {
      navigate(`/sale/digital/${cardId}/${card.card_stock_id}`);
      handleClose();
    }else{
      return;
    }
  };

  if (isLoading) {
    return (
      <ModalWrapper>
        <PageLoadingComponent />
      </ModalWrapper>
    );
  }

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position={"bottom"}>
      <Wrapper>
        <CommonHeader title="판매 카드 선택"
                      explain={{
                        title: "판매 카드 선택",
                        text: "시리얼 넘버로 등록된 디지털 카드는 판매 및 교환이 불가합니다.",
                      }}
                      onClickArrow={handleClose} />
        <div className="info-wrapper">
          <ProductSummaryInfo card={data.card} imageUrl={data.card.image_url} />
        </div>

        <div className="card-list-wrapper">
          {data.user_digital_cards.map((card: SummaryDigitalCardType) => (
            <SelectCard
              card={card}
              key={card.id}
              onClick={handleClickCard(card)}
            />
          ))}
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background: #fff;

  height: 90%;

  border-radius: 20px 20px 0px 0px;

  & .info-wrapper {
    padding: 16px 20px;
  }

  & .card-list-wrapper {
    margin-top: 20px;
    padding: 0 20px;

    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    grid-column-gap: 12px;
    grid-row-gap: 16px;
  }
`;

export default SaleDigitalDetailModal;
