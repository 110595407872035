import React from "react";
import styled from "styled-components";
import CommunitySlides from "../../components/card/CommunitySlides";
import Title from "../../components/common/Title";
import { useProductDetail } from "../../providers/ProductDetailProvider";

interface Props {
  className?: string;
}

function ProductCommunitiesContainer({ className }: Props) {
  const { communities } = useProductDetail();
  return (
    <Wrapper className={className ? className : ""}>
      <Title title="관련 서포터즈 게시글" />
      <CommunitySlides communiteis={communities || []} />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default ProductCommunitiesContainer;
