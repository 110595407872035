import React from "react";
import { FadeLoader } from "react-spinners";
import BeatLoader from "react-spinners/BeatLoader";
import styled from "styled-components";
interface LoadingComponentProps {}

function LoadingComponent({}: LoadingComponentProps) {
  return (
    <Wrapper className="center">
      <FadeLoader color="rgb(212, 246, 72)" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 0;
`;

export default LoadingComponent;
