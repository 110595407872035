const color = {
  white: "#FFFFFF",

  main: "#1E1E1E",
  sub: "#D4F648",
  system1: "#66A3FF",
  system2: "#FF7C7C",

  gray1: "#1E1E1E",
  gray2: "#3A3A3A",
  gray3: "#5D5D5D",
  gray4: "#838383",
  gray5: "#ACACAC",
  gray6: "#C7C7C7",
  gray7: "#E7E7E7",
  gray8: "#F3F3F3",
  gray9: "#F9F9F9",
  gray10: "#FCFCFC",
};

export type ColorType = typeof color;

export default { color };
