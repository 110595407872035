import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { refundModalState, RefundSubmitType } from "../../recoil/atoms/modal";
import { paymentPayloadState } from "../../recoil/atoms/payment";
import { convertListVariables } from "../../utils/utils";
import { BANK_VARIABLES, PAYMENT_METHOD } from "../../utils/variables";

interface SelectPaymentMethodProps {}

function SelectPaymentMethod({}: SelectPaymentMethodProps) {
  const [payload, setPayload] = useRecoilState(paymentPayloadState);
  const setRefundModal = useSetRecoilState(refundModalState);

  const handleChagneMethod = (value: string) => () => {
    setPayload((old) => ({
      ...old,
      method: value,
    }));
  };

  const handleClickRegisterRefund = () => {
    const onSubmit: RefundSubmitType = (values) => {
      setPayload((old) => ({
        ...old,
        refundInfo: values,
      }));
    };
    setRefundModal({
      onSubmit,
      defaultValue: payload.refundInfo && payload.refundInfo,
    });
  };

  return (
    <Wrapper>
      <h4 className="title">결제 수단 선택</h4>
      <div className="method-wrapper mt-14">
        {convertListVariables(PAYMENT_METHOD).map(({ value, label }) => (
          <div
            className={`method-button ${
              value === payload.method ? "active" : ""
            }`}
            key={value}
            onClick={handleChagneMethod(value)}
          >
            {label}
          </div>
        ))}
      </div>
      {payload.method === "VBANK" && (
        <div className="refind-wrapper mt-16">
          <span className="refund-label">환불계좌</span>
          <div className="refund-value-wrapper">
            {payload.refundInfo?.account &&
              payload.refundInfo?.bank &&
              payload.refundInfo?.owner && (
                <span className="refund-value">
                  <span>{BANK_VARIABLES[payload.refundInfo.bank]}</span>{" "}
                  <span>{payload.refundInfo.account}</span>{" "}
                  <span>({payload.refundInfo.owner})</span>
                </span>
              )}
          </div>
          <span
            className="refund-register-button"
            onClick={handleClickRegisterRefund}
          >
            {payload.refundInfo ? "변경" : "등록"}
          </span>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 26px;

  & .method-wrapper {
    display: flex;
    gap: 10px;
  }

  & .method-button {
    width: 100%;
    height: 42px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid rgba(199, 199, 199, 1);
    color: rgba(131, 131, 131, 1);

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;

    cursor: pointer;
  }

  & .method-button.active {
    color: rgba(30, 30, 30, 1);
    font-weight: 700;
    border: 1px solid rgba(0, 0, 0, 1);
  }

  & .refind-wrapper {
    display: flex;
    gap: 6px;

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
  }

  & .refund-label {
    color: rgba(93, 93, 93, 1);
    flex-shrink: 1;
  }

  & .refund-value-wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  & .refund-value {
    color: rgba(93, 93, 93, 1);
    font-weight: 500;
  }

  & .refund-register-button {
    flex-shrink: 1;
    color: rgba(131, 131, 131, 1);
    text-decoration: underline;

    margin-left: 6px;

    cursor: pointer;
  }
`;

export default SelectPaymentMethod;
