import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { addToCard } from "../../../apis/common";
import { paymentModalState } from "../../../recoil/atoms/modal";
import { customToast, getThousandCommaPrice } from "../../../utils/utils";
import SellerProfile from "../../card/SellerProfile";
import PaymentInfo from "../../common/PaymentInfo";
import Button from "../../elements/Button";
import ModalWrapper from "../ModalWrapper";

function PaymentModal() {
  const [modal, setModal] = useRecoilState(paymentModalState);

  const hanldeClose = () => {
    setModal(null);
  };

  const handleBuy = () => {
    hanldeClose();
    modal?.onBuy();
  };

  const handleBasket = () => {
    if (!modal) return;

    hanldeClose();
    modal?.onBasket();
  };

  const deliveryPrice = useMemo(() => {
    if (!modal) return 0;
    return modal.data.basic_delivery_fee;
  }, [modal?.data]);

  const deliveryText = useMemo(() => {
    if (!modal) return;
    if (modal.data.use_free_delivery_fee !== 1) return undefined;
    return `${getThousandCommaPrice(
      modal.data.free_delivery_fee_standard
    )}원 이상 무료배송`;
  }, [modal]);

  useEffect(() => {
    return () => {
      setModal(null);
    };
  }, []);

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      hanldeClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={hanldeClose}>
      <Wrapper>
        <div className="header">
          <SellerProfile
            userId={modal.data.id}
            nickname={modal.data.nickname}
            profileImage={modal.data.profile_url}
            subText={deliveryText}
          />
        </div>
        <div className="container">
          <PaymentInfo price={modal.data.price} deliveryPrice={deliveryPrice} />
        </div>
        <div className="footer">
          <Button styleType="line" onClick={handleBasket}>
            장바구니
          </Button>
          <Button onClick={handleBuy}>구매하기</Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 20px 20px 0px 0px;

  padding: 0 20px;

  width: 100%;

  background: #fff;

  & .header {
    padding-top: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(231, 231, 231, 1);
  }

  & .container {
    margin-top: 16px;
  }

  & .footer {
    margin-top: 10px;
    padding: 15px 0;
    display: flex;
    gap: 8px;
  }
`;

export default PaymentModal;
