import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {paymentMoneyState, paymentPointState} from "../../recoil/atoms/payment";
import { getThousandCommaPrice, onChangeNumberType } from "../../utils/utils";
import Input from "../elements/Input";
import PaymentButton from "./PaymentButton";
import useConfig from "../../hooks/useConfig";
import CommonHeader from "../common/CommonHeader";
import ExplainComponent from "../common/ExplainComponent";

interface RegisterPointProps {
    myMoney?: number;
    paymentPrice?: number;
    couponPrice?: number;
    pointPrice?: number;
    deliveryFee?: number;
    productPrice?: number;
}

function RegisterMoney({
                           myMoney = 0,
                           paymentPrice = 0,
                           couponPrice = 0,
                           pointPrice = 0,
                           deliveryFee = 0,
                           productPrice = 0,
                       }: RegisterPointProps) {
    const [money, setMoney] = useState("");
    const [paymentMoney, setPaymentMoney] = useRecoilState(paymentMoneyState);

    const isValid = useMemo(() => {
        if (!myMoney) return false;
        return true;
    }, [myMoney]);

    const currentPrice = useMemo(() => {
        return Number(productPrice) + Number(deliveryFee) - Number(couponPrice) - Number(pointPrice);
    }, [productPrice, couponPrice, pointPrice]);

    useEffect(() => {
        if(Number(money) > currentPrice){
            setMoney(String(Math.round(currentPrice)));
            setPaymentMoney(Math.round(currentPrice));
        }
    }, [currentPrice]);

    const handleChangeMoney = onChangeNumberType((value) => {
        if (Number(value) > Number(myMoney)) return;
        if (Number(value) > currentPrice) {
            setMoney(String(Math.round(currentPrice)));
            return;
        }

        setMoney(value);
    }, true);

    const handleBlur = () => {
        setPaymentMoney(Number(money));
    };

    const handleClickPaymentButton = () => {
        if (myMoney >= (currentPrice)) {
            return setPaymentMoney(Math.round(currentPrice));
        }

        setPaymentMoney(Number(myMoney));
    };

    useEffect(() => {
        setMoney(String(paymentMoney));
    }, [paymentMoney]);

    return (
        <Wrapper>
            <h4 className="title">TIF MONEY</h4>
            <Input
                label="TIF MONEY"
                placeholder="0"
                className="mt-14 line"
                unit={
                    <>
                        <PaymentButton onClick={handleClickPaymentButton} disabled={!isValid}>
                            모두 사용
                        </PaymentButton>
                    </>
                }
                subText={`보유 TIF MONEY ${getThousandCommaPrice(
                    (myMoney || 0) - paymentMoney
                )}원`}
                value={getThousandCommaPrice(money)}
                onChange={handleChangeMoney}
                disable={!myMoney || !isValid}
                onBlur={handleBlur}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-top: 30px;
`;

export default RegisterMoney;
