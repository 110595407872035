import { atom } from "recoil";
import { v1 } from "uuid";
import {
  AttendanceRandomItemType,
  BadgeType,
  CardExchangeType, categoryCheckType,
  DigitalCardDetailType,
  GiftType,
  levelCategoryCheckType,
  midCategoryCheckType, paybackFilterCategoryCheckType,
  PickListType,
  SaleOrderType,
} from "../types/api";

type TwoButtonModalType = {
  title: string;
  text: string;
  findId?: string;
  confirmText?: string;
  cancleText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const twoButtonModalState = atom<TwoButtonModalType>({
  key: `twoButtonModalState`,
  default: null,
});

type alertModalType = {
  title: string;
  text: string;
  findId?: string;
  findType?: string;
  confirmText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const alertModalState = atom<alertModalType>({
  key: `alertModalState`,
  default: null,
});

type termModalType = {
  type: string;
  title?: string;
} | null;

export const termModalState = atom<termModalType>({
  key: `termModalState`,
  default: null,
});

type selectModalType = {
  options: { value: string; text?: string }[];
  value?: string;
  onSelect?: (value: { value: string; text?: string }) => void;
} | null;

export const selectModalState = atom<selectModalType>({
  key: `selectModalState`,
  default: null,
});

type toastModalType = {
  text: string;
  high?: boolean;
} | null;

export const toastModalState = atom<toastModalType>({
  key: `toastModalState`,
  default: null,
});

type digitalCardBuyModalType = {
  promotion_id: number;
  card_id?: number;
} | null;

export const digitalCardBuyModalState = atom<digitalCardBuyModalType>({
  key: `digitalCardBuyModalState`,
  default: null,
});

type digitalCardAmountModalType = {
  promotion_id: number;
  card_id?: number;
  id: number;
} | null;

export const digitalCardAmountModalState = atom<digitalCardAmountModalType>({
  key: `digitalCardAmountModalState`,
  default: null,
});

type saleKindModalType = {
  visiable: boolean;
  type?: "sale" | "buy";
  disabledDigital?: boolean;
  disabledGoods?: boolean;
  onClickGoods?: () => void;
  onClickDigital?: () => void;
} | null;

export const saleKindModalState = atom<saleKindModalType>({
  key: `saleKindModalState/${v1()}`,
  default: null,
});

type saleModalType = {
  onConfirm: (item: PickListType, type: 0 | 1) => void;
  onClose?: () => void;
  first?: boolean;
} | null;

export const saleModalState = atom<saleModalType>({
  key: `saleModalState`,
  default: null,
});

type communityProductModalType = {
  onConfirm: (item: PickListType) => void;
} | null;

export const communityProductModalState = atom<communityProductModalType>({
  key: `communityProductModalState`,
  default: null,
});

type saleDigitalModalType = {
  first?: boolean;
  onConfirm: (item: DigitalCardDetailType) => void;
  onClose?: () => void;
  cardId?: string;
} | null;

export const saleDigitalModalState = atom<saleDigitalModalType>({
  key: `saleDigitalModalState`,
  default: null,
});

type communitySubmitModalType = {
  is_supporter: boolean;
} | null;

export const communitySubmitModalState = atom<communitySubmitModalType>({
  key: `communitySubmitModalState`,
  default: null,
});

type communityMenuModalType = {
  type: "community" | "supporters";
  id: number;
  user_id: number;
  my_post: boolean;
} | null;

export const communityMenuModalState = atom<communityMenuModalType>({
  key: `communityMenuModalState`,
  default: null,
});

type commentMenuModalType = {
  type: "community" | "supporters";
  menu_type? : string;
  id: number;
  user_id: number;
  my_post: boolean;
  onEdit?: () => void;
  mutate: () => void;
} | null;

export const cardMenuModalState = atom<cardMenuModalType>({
  key: `cardMenuModalState`,
  default: null,
});

type cardMenuModalType = {
  seller_id: number;
  my_post: boolean;
  card_id: number;
  card_stock_id: number;
} | null;

export const commentMenuModalState = atom<commentMenuModalType>({
  key: `commentMenuModalState`,
  default: null,
});

type profileMenuModalType = {
  id: string;
} | null;

export const profileMenuModalState = atom<profileMenuModalType>({
  key: `profileMenuModalState`,
  default: null,
});

type reviewMenuModalType = {
  id: number;
  user_id: number;
  my_post: boolean;
  mutate: () => void;
  type: string;
  exchange_id: number;
  exchange_user_digital_card_id: number;
} | null;

export const reviewMenuModalState = atom<reviewMenuModalType>({
  key: `reviewMenuModalState`,
  default: null,
});

type BadgeModalType = {
  item: BadgeType;
  mutate: () => void;
} | null;

export const BadgeModalState = atom<BadgeModalType>({
  key: `BadgeModalState`,
  default: null,
});

type PostModalType = {
  visible: boolean;
  onClick?: (postcode: string, address: string) => void;
} | null;

export const postModalState = atom<PostModalType>({
  key: `postModalState`,
  default: null,
});

type exchangeModalType = {
  select: CardExchangeType[];
  setSelect: (item: CardExchangeType[]) => void;
} | null;

export const exchangeModalState = atom<exchangeModalType>({
  key: `exchangeModalState`,
  default: null,
});

type paybackModalType = {
  selectResult: CardExchangeType[];
  select: number[];
  setSelect: (item: number[]) => void;
  mutate: () => void;
} | null;

export const paybackModalState = atom<paybackModalType>({
  key: `paybackModalState`,
  default: null,
});

type saleOrderMenuModalType = {
  cardId: number;
  item: SaleOrderType;
  type: "digital" | "pack" | "card";
  mutate: () => void;
} | null;

export const saleOrderMenuModalState = atom<saleOrderMenuModalType>({
  key: `saleOrderMenuModalState`,
  default: null,
});

type saleReadyDetailModalType = {
  cardId: number;
  item: SaleOrderType;
  type: "digital" | "pack" | "card";
} | null;

export const saleReadyDetailModalState = atom<saleReadyDetailModalType>({
  key: `saleReadyDetailModalState`,
  default: null,
});

type attendanceRandomModalType = {
  item: AttendanceRandomItemType;
} | null;

export const attendanceRandomModalState = atom<attendanceRandomModalType>({
  key: `attendanceRandomModalState`,
  default: null,
});

type giftModalType = {
  item: GiftType;
  card?: { category_name: string; id: number; image_url: string; name: string };
} | null;

export const giftModalState = atom<giftModalType>({
  key: `giftModalState`,
  default: null,
});

type PictureDetailModalType = {
  start: number;
  disabledEncoding?: boolean;
  picture: { image_url: string; id: number }[];
  onClick: (index: number) => void;
} | null;

export const pictureDetailModalState = atom<PictureDetailModalType>({
  key: `pictureDetailModalState`,
  default: null,
});

type attendanceModalType = {
  visiable: boolean;
} | null;

export const attendanceModalState = atom<attendanceModalType>({
  key: `attendanceModalState`,
  default: null,
});

type reportModalType = {
  onSelect?: (value: string) => void;
  type?: string;
} | null;

export const reportModalState = atom<reportModalType>({
  key: `reportModalState`,
  default: null,
});

type supportersModalType = {
  visiable: boolean;
} | null;

export const supportersModalState = atom<supportersModalType>({
  key: `supportersModalState`,
  default: null,
});

type profileModalType = {
  nickname: string;
  mutate: () => void;
  handleProfile: (e: any) => void;
  handleRemoveProfile: () => void;
} | null;

export const profileModalState = atom<profileModalType>({
  key: `profileModalState`,
  default: null,
});

type digitalCardSNRegModalType = {
  first?: boolean;
  onClose?: () => void;
} | null;

export const digitalCardSNRegModalState = atom<digitalCardSNRegModalType>({
  key: `digitalCardSNRegModalState`,
  default: null,
});

type moneyWithdrawModalType = {
  first?: boolean;
  onClose?: () => void;
} | null;

export const moneyWithdrawModalState = atom<moneyWithdrawModalType>({
  key: `moneyWithdrawModalState`,
  default: null,
});

type midCategoryModalType = {
  visiable: boolean;
  reset?: boolean;
  data?: midCategoryCheckType[];
  caregory_id?: number;
  onMidChecked?: (item: midCategoryCheckType[]) => void;
  onLevelChecked?: (item: levelCategoryCheckType[]) => void;
  minMoney?: (item: number) => void;
  maxMoney?: (item: number) => void;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const midCategoryModalState = atom<midCategoryModalType>({
  key: `midCategoryModalState`,
  default: null,
});

type paybackFilterModalType = {
  visiable: boolean;
  reset?: boolean;
  data?: categoryCheckType[];
  categoryIds?: (item: paybackFilterCategoryCheckType[]) => void;
  boxCategoryIds?: (item: number[]) => void;
  onCategoryChecked?: (item: categoryCheckType[]) => void;
  onLevelChecked?: (item: levelCategoryCheckType[]) => void;
  setFilterReset: (item: boolean) => void;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const paybackFilterModalState = atom<paybackFilterModalType>({
  key: `paybackFilterModalState`,
  default: null,
});

type trackingExcelModalType = {
  title?: string;
  visible?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const trackingExcelModalState = atom<trackingExcelModalType>({
  key: `trackingExcelModalState`,
  default: null,
});
