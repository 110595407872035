//IE동작 도움
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import * as ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./client/App";

import "./styles/font.css";
import "swiper/css";
import "swiper/css/pagination";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter } from "react-router-dom";

//react 버전 18부터 ReactDom.render 사용 X
//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>
);
