import React from "react";
import styled from "styled-components";
import { ReactComponent as BlackArrowIcon } from "../../assets/icons/black-arrow-icon.svg";

interface TitleProps {
  title: string;
  arrow?: {
    onClick: () => void;
  };
}

function Title({ title, arrow }: TitleProps) {
  return (
    <Wrapper>
      <h3 className="title">{title}</h3>
      {arrow && (
        <div className="icon-wrapper" onClick={arrow.onClick}>
          <BlackArrowIcon className="arrow-icon" />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & .title {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;

    color: rgba(30, 30, 30, 1);
  }

  & .icon-wrapper {
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .arrow-icon {
    width: 7px;
  }
`;

export default Title;
