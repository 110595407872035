import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { alertModalState, supportersModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { api } from "../../utils/api";
import Button from "../elements/Button";

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";
import Input from "../elements/Input";

const SupportersModal = () => {
  const [modal, setModal] = useRecoilState(supportersModalState);

  const setAlertModal = useSetRecoilState(alertModalState);

  const [email, setEmail] = useState<string>("");
  const [channel, setChannel] = useState<string>("");

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("channel_url", channel);

    const {
      data: { success, alert, data },
    } = await api.post("/supporter/request", formData);

    if (success) {
      handleClose();
      setAlertModal({
        title: "서포터즈 신청 완료",
        text: "서포터즈 신청이 완료되었습니다.<br/>서포터즈 선정시 메일로 안내드리겠습니다.",
      });
    } else {
      window.alert(alert);
    }
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>
          서포터즈 신청하기
          <Close onClick={handleClose} />
        </PopupTitle>
        <PopupText>
          <Form>
            <Input
              label="이메일"
              placeholder="연락받으실 이메일을 입력해주세요."
              value={email}
              onChange={(e) => {
                let value = e.target.value.slice(0, 255);
                if (value.length <= 255) {
                  setEmail(value);
                }
              }}
              borderRadius="10px 10px 0 0"
              borderBottom="0"
            />
            <span />
            <Input
              label="운영 채널"
              placeholder="블로그, 인스타그램, 유튜브 등의 url을 입력"
              value={channel}
              onChange={(e) => {
                let value = e.target.value.slice(0, 255);
                if (value.length <= 255) {
                  setChannel(value);
                }
              }}
              borderRadius="0 0 10px 10px"
            />
          </Form>
        </PopupText>
        <ButtonBox>
          <Button
            disabled={email === "" || channel === ""}
            onClick={handleSubmit}
          >
            신청
          </Button>
        </ButtonBox>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SupportersModal;

const ButtonBox = styled.div`
  padding: 15px 20px;
`;

const Form = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.color.gray7};

  & > span {
    width: 100%;
    height: 1px;
    display: block;
    background: ${(props) => props.theme.color.gray7};
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

const PopupTitle = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};
  text-align: center;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
