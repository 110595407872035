import React, {useState} from "react";
import styled from "styled-components";
import {SaleCardType} from "../../types/product";
import {Dot} from "../../utils/CommonFunction";
import {useNavigate, useParams} from "react-router-dom";
import { ReactComponent as Sold } from "../../assets/icons/sold-icon.svg";
import Button from "../elements/Button";
import {useSetRecoilState} from "recoil";
import {alertModalState} from "../../utils/atom";
import { ReactComponent as PlusIcon } from "../../assets/icons/view-plus.svg";
import { ReactComponent as MinusIcon } from "../../assets/icons/view-minus.svg";
function ProductSale({item}: { item: SaleCardType[]}) {

    const setAlertModal = useSetRecoilState(alertModalState);
    const { cardId } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);

    const handleDetail  = (cardId: any, id: any, status: any) => () => {
        navigate("/buy/goods/" + cardId + "/" + id + "?status=" + status)
    }

    const handleAll  = (cardId: any) => () => {
        navigate("/sale/goods/list/" + cardId);
    }

    return (
        <Wrapper>
            <SaleCard>
                <table style={{borderCollapse: "collapse", borderSpacing: "0"}}>
                    <tbody>
                    <tr>
                        <td style={{padding:"0 calc(50% - 159px)"}}>
                            {!open ?
                                item.slice(0, 9).map((item, i: number) => (
                                    <ImageDiv>
                                        <ImageBox>
                                            <div onClick={handleDetail(item.card_id, item.id, item.status)}>
                                                <img src={item.image_url} alt=""/>
                                            </div>
                                            <TextBox
                                                onClick={handleDetail(item.card_id, item.id, item.status)}>
                                                {item.status == 1 && <Sold/>}
                                                <p className="ellipsis">
                                                    <br/>
                                                    <br/><br/><br/><br/>
                                                    &nbsp;{item.card_grading}<br/>
                                                    &nbsp;{Dot(item.price)} 원
                                                </p>
                                            </TextBox>
                                        </ImageBox>
                                    </ImageDiv>
                                ))
                            :
                                item.map((item, i: number) => (
                                    <ImageDiv>
                                        <ImageBox>
                                            <div onClick={handleDetail(item.card_id, item.id, item.status)}>
                                                <img src={item.image_url} alt=""/>
                                            </div>
                                            <TextBox
                                                onClick={handleDetail(item.card_id, item.id, item.status)}>
                                                <p className="ellipsis">
                                                    {item.status == 1 && <Sold/>}
                                                    <br/>
                                                    <br/><br/><br/><br/>
                                                    &nbsp;{item.card_grading}<br/>
                                                    &nbsp;{Dot(item.price)} 원
                                                </p>
                                            </TextBox>
                                        </ImageBox>
                                    </ImageDiv>
                                ))
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </SaleCard>
            {item.length > 9 &&
            <ButtonWrapper>
                <Button styleType="line" onClick={handleAll(cardId)} >
                    더 보기
                </Button>
            </ButtonWrapper>
            }
{/*            {!open ?
                (item.length > 9 ?
                <ButtonWrapper>
                    <Button styleType="line" onClick={() => setOpen(true)} >
                    더 보기
                    </Button>
                </ButtonWrapper>
                :
                <></>
                )
            :
                <ButtonWrapper>
                    <Button styleType="line" onClick={() => setOpen(false)} >
                    닫기
                    </Button>
                </ButtonWrapper>
            }*/}
        </Wrapper>
    );
}

const TextBox = styled.div`

    & > p {
        border-radius: 5px;
        background-image: linear-gradient(to bottom,
        rgba(20, 20, 20, 0) 10%,
        rgba(20, 20, 20, 0.15) 25%,
        rgba(20, 20, 20, 0.30) 50%,
        rgba(20, 20, 20, 0.45) 75%,
        rgba(20, 20, 20, 0.60) 100%
        );
        position: absolute;
        /*    top: 62px;
        left: 5px;*/
        width: 100%;
        height: 100%;
        font-size: 13px;
        font-weight: 400;
        color: white;
        /*    padding: 3px;*/
    }
`;

const ImageBox = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background: ${(props) => props.theme.color.gray8};
    
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const SaleCard = styled.div`
  cursor: pointer;
`;

const ImageDiv = styled.div`
    display: inline-block;
    margin: 3px 3px;
`;

const Wrapper = styled.div`
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: 17px;
  width: 100%;
`;
export default ProductSale;
