import React from "react";
import styled from "styled-components";
import { ProductCardType } from "../../types/product";
import CommonSummaryInfo from "../card/CommonSummaryInfo";

interface ProductSummaryInfoProps {
  card: ProductCardType;
  imageUrl?: string;
}

function ProductSummaryInfo({ card, imageUrl }: ProductSummaryInfoProps) {
  return (
    <Wrapper>
      <div
        className="summary-image"
        style={{
          backgroundImage: `url("${imageUrl}")`,
          backgroundPosition: "center center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      <CommonSummaryInfo
        categoryName={card.category_name}
        name={card.name}
        subName={card.sub_name}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;

  gap: 14px;

  & .summary-image {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    background-color: rgba(243, 243, 243, 1);
  }
`;

export default ProductSummaryInfo;
