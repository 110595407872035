import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  alertModalState,
  reportModalState,
  reviewMenuModalState,
  twoButtonModalState,
} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Menu1 } from "../../assets/icons/commu-menu1-icon.svg";
import { ReactComponent as Menu2 } from "../../assets/icons/commu-menu2-icon.svg";
import { ReactComponent as Menu3 } from "../../assets/icons/commu-menu3-icon.svg";
import { ReactComponent as Menu4 } from "../../assets/icons/commu-menu4-icon.svg";
import { customToast } from "../../utils/utils";

const ReviewMenuModal = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useRecoilState(reviewMenuModalState);

  const setReportModal = useSetRecoilState(reportModalState);
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);
  const setAlertModal = useSetRecoilState(alertModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleDelete = async () => {
    const {
      data: { success, alert, data },
    } = await api.delete("/community/comment/delete?comment_id=" + modal?.id);

    if (success) {
      handleClose();
      modal?.mutate && modal?.mutate();
      customToast("리뷰가 삭제되었습니다.");
    } else {
      window.alert(alert);
    }
  };

  const handleBlock = async () => {
    const formData = new FormData();
    formData.append(
      "target_user_id",
      modal?.user_id ? String(modal?.user_id) : ""
    );

    const {
      data: { success, alert, data },
    } = await api.post("/block", formData);

    if (success) {
      handleClose();
      modal?.mutate && modal?.mutate();
      customToast("차단되었습니다.");
    } else {
      window.alert(alert);
    }
  };

  const handleReport = async (value: string) => {
    const formData = new FormData();
    formData.append("review_id", String(modal?.id));
    formData.append("report_type", value);

    const {
      data: { success, alert, data },
    } = await api.post("/review/report", formData);

    if (success) {
      handleClose();
      setAlertModal({
        title: "신고 완료",
        text: "[코멘트/댓글/게시글]<br/>신고가 완료되었습니다.",
      });
    } else {
      window.alert(alert);
    }
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          {modal?.my_post ? (
            <>
              {modal.type != 'exchange' &&
              <List
                  onClick={() => {
                    handleClose();
                    navigate("/review/" + modal?.id);
                  }}
              >
                <Menu1 />
                수정
              </List>
              }
              {modal.type == 'exchange' &&
                  <List
                      onClick={() => {
                        handleClose();
                        navigate("/mypage/exchange/review/" + modal?.exchange_id + "?card_id=" + modal?.exchange_user_digital_card_id);
                      }}
                  >
                    <Menu1 />
                    수정
                  </List>
              }
              <List
                onClick={() =>
                  setTwoButtonModal({
                    title: "리뷰 삭제",
                    text: "리뷰를 삭제하시겠습니까?",
                    onConfirm: handleDelete,
                  })
                }
              >
                <Menu3 />
                삭제
              </List>
            </>
          ) : (
            <>
              <List
                onClick={() =>
                  setReportModal({
                    onSelect: (value: string) => handleReport(value),
                  })
                }
              >
                <Menu2 />
                신고
              </List>
              <List
                onClick={() =>
                  setTwoButtonModal({
                    title: "회원 차단",
                    text: "리뷰 작성 회원을 차단하시겠습니까?",
                    onConfirm: handleBlock,
                  })
                }
              >
                <Menu4 />
                차단
              </List>
            </>
          )}
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ReviewMenuModal;

const List = styled.button`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  color: ${(props) => props.theme.color.gray2};
  cursor: pointer;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding-top: 20px;
  overflow: auto;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
