import React from "react";
import styled from "styled-components";
import Button from "../elements/Button";

interface FixedButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  children?: React.ReactNode;
}

function FixedButton({ onClick, children, ...rest }: FixedButtonProps) {
  return (
    <Wrapper>
      <div className="submit-wrapper">
        <Button onClick={onClick} {...rest}>
          {children}
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;

  display: flex;
  justify-content: center;

  & .submit-wrapper {
    width: 100%;
    max-width: 840px;

    padding: 15px 20px;
  }
`;

export default FixedButton;
