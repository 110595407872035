import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import ResultSuccess from "./ResultSuccess";
import ResultFail from "./ResultFail";

function PaymentResult() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { success, id, type } = qs.parse(search.slice(1));

  // 결제완료페이지 진입시에 localStorage에 저장했던 state값 제거
  useEffect(() => {
    localStorage.removeItem("state");
    window.history.replaceState("", "", "/mypage");

    if (type !== "exchange") return;
    navigate(`/mypage/exchange/${id}`);
  }, []);

  useEffect(() => {
    if (success === "true") return;
    navigate("/main");
  }, [success]);

  if (success === "true" && type !== "exchange") return <ResultSuccess orderId={id as string} />;
  return <ResultFail />;
}

export default PaymentResult;
