import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { reportModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";

const ReportModal = () => {
  const [modal, setModal] = useRecoilState(reportModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleSelect = (value: string) => {
    modal?.onSelect && modal?.onSelect(value);
    handleClose();
  };

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>
          신고 유형 선택
          <Close onClick={handleClose} />
        </PopupTitle>
        <PopupText>
          <List onClick={() => handleSelect("홍보 및 스팸성")}>
            홍보 및 스팸성
          </List>
          {modal?.type == "card" &&
              <List onClick={() => handleSelect("위조 제품 의심")}>
                위조 제품 의심
              </List>
          }
          <List onClick={() => handleSelect("욕설 및 혐오 표현")}>
            욕설 및 혐오 표현
          </List>
          <List onClick={() => handleSelect("불법적인 정보")}>
            불법적인 정보
          </List>
          <List onClick={() => handleSelect("개인정보 노출 및 저작권 침해")}>
            개인정보 노출 및 저작권 침해
          </List>
          <List onClick={() => handleSelect("음란물")}>음란물</List>
          <List onClick={() => handleSelect("기타")}>기타</List>
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ReportModal;

const List = styled.button`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 16px;
  color: ${(props) => props.theme.color.gray2};

  &.select {
    font-weight: 700;
    background: #f9f9f9;
  }
`;

const PopupText = styled.div`
  width: 100%;
`;

const PopupTitle = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};
  text-align: center;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
