import React, { useState } from "react";
import styled, { css } from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";
import { ReactComponent as ArrowIcon } from "../../assets/icons/black-arrow-icon.svg";
import Divide from "../common/Divide";
import {Dot} from "../../utils/CommonFunction";

interface TotalPaymentPriceProps {
  couponPrice?: number;
  deliveryFee?: number;
  paymentPrice: number;
  point?: number;
  money?: number;
  totalPrice?: number;
  exchangeFee?: number;
  exchangeFeePromotion?: number;
  disableHide?: boolean;
  disableBorder?: boolean;
  disableTotal?: boolean;
  isBoldTitle?: boolean;
  orderDetails?: any;
}

function TotalPaymentPrice({
  couponPrice,
  deliveryFee,
  paymentPrice,
  point,
  money,
  totalPrice,
  exchangeFee,
  exchangeFeePromotion,
  disableHide,
  disableBorder,
  disableTotal,
  isBoldTitle,
  orderDetails
}: TotalPaymentPriceProps) {
  const [open, setOpen] = useState(!!disableHide);

  const toggleDetail = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  
  const renderPrice = (price: number) => {
    if (price === 0) return "-";
    return getThousandCommaPrice(price) + "원";
  };

  return (
    <Wrapper disableBorder={disableBorder} isBoldTitle={isBoldTitle}>
      <div className="main-label-wrapper">
        <label className="main-label">
          {disableTotal ? "" : "총"} 결제금액
        </label>

        <div className="main-right-wrapper">
          <h4 className="main-value">
            {getThousandCommaPrice(paymentPrice)}원
          </h4>
          {!disableHide && (
            <div
              className={`icon-wrapper ${open ? "up" : "down"}`}
              onClick={toggleDetail}
            >
              <ArrowIcon className="arrow-icon" />
            </div>
          )}
        </div>
      </div>

      {open && (
        <>
          <Divide
            margin={16}
            backgroundColor={disableBorder ? "#fff" : undefined}
          />
          <div className="detail-prices-wrapper">
            {(exchangeFee === 0 || exchangeFee) && (
                <div className="detail-box">
                  <div className="detail-label">교환 수수료</div>
                  <div className="detail-value"
                       style={exchangeFeePromotion ? {textDecoration: "line-through"} : {}}>{renderPrice(exchangeFee)}</div>
                </div>
            )}
            {(exchangeFeePromotion === 0 || exchangeFeePromotion) && (
                <>
                  {(exchangeFeePromotion > 0) && (
                      <div className="detail-box">
                        <div className="detail-label">프로모션 수수료</div>
                        <div className="detail-value">{renderPrice(exchangeFeePromotion)}</div>
                      </div>
                  )}
                </>
            )}
            {(totalPrice === 0 || totalPrice) && (
                <div className="detail-box">
                  <div className="detail-label">상품 구매 금액</div>
                  <div className="detail-value">{renderPrice(totalPrice)}</div>
                </div>
            )}
            {(deliveryFee === 0 || deliveryFee) && (
                <div className="detail-box">
                  <div className="detail-label">배송비</div>
                  <div className="detail-value">{renderPrice(deliveryFee)}</div>
                </div>
            )}
            {(couponPrice === 0 || couponPrice) && (
                <div className="detail-box">
                  <div className="detail-label">쿠폰 할인</div>
                  <div className="detail-value">{renderPrice(couponPrice)}</div>
                </div>
            )}
            {(point === 0 || point) && (
                <div className="detail-box">
                  <div className="detail-label">TIF COIN</div>
                  <div className="detail-value">{renderPrice(point)}</div>
                </div>
            )}
            {(money === 0 || money) && (
                <div className="detail-box">
                  <div className="detail-label">TIF MONEY</div>
                  <div className="detail-value">{renderPrice(money)}</div>
                </div>
            )}
            <div className="detail-box">
              <div className="detail-label">결제 상세 정보</div>
            </div>
            {orderDetails?.map((item : any, i: number) => (
                <>
                  <div className="detail-box" style={{fontSize: "10px"}}>
                    <div className="detail-label" style={{fontSize: "12px"}}>- 판매자 : {item.nickname}</div>
                  </div>
                  <div className="detail-box" style={{fontSize: "10px"}}>
                    <div className="detail-value" style={{fontSize: "11px"}}>
                      (결제: {Dot(Number(item?.total_product_price ?? 0) + Number(item?.delivery_fee ?? 0) - Number(item?.coupon_price ?? 0) - Number(item?.point ?? 0) - Number(item?.money ?? 0))}원, 쿠폰 : {Dot(item?.coupon_price ?? 0)}원, TIF COIN
                      : {Dot(item?.point ?? 0)}코인, TIF MONEY : {Dot(item?.money ?? 0)}원)
                    </div>
                  </div>
                </>
            ))}
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ disableBorder?: boolean; isBoldTitle?: boolean }>`
  margin-top: 30px;

  ${(props) =>
      !props.disableBorder &&
      css`
        border-top: 1px solid rgba(93, 93, 93, 1);
        border-bottom: 1px solid rgba(93, 93, 93, 1);
      `}

  padding: 12px 0 16px 0;

  & .main-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .main-label {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);

    ${(props) =>
      props.isBoldTitle &&
      css`
        color: rgba(30, 30, 30, 1);
      `}
  }

  & .main-value {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(30, 30, 30, 1);
  }

  & .main-right-wrapper {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  & .icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & .down {
    transform: rotate(90deg);
  }

  & .up {
    transform: rotate(-90deg);
  }

  & .arrow-icon {
    width: 12px;
    height: 12px;
  }

  & .detail-prices-wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  & .detail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .detail-label {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }

  & .detail-value {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(93, 93, 93, 1);
  }
`;

export default TotalPaymentPrice;
