import React from "react";
import { useRecoilValue } from "recoil";
import CouponModal from "../../components/modal/payment/CouponModal";
import RefundModal from "../../components/modal/payment/RefundModal";
import { couponModalState, refundModalState } from "../../recoil/atoms/modal";

function PaymentCore() {
  const refundModal = useRecoilValue(refundModalState);
  const couponModal = useRecoilValue(couponModalState);

  return (
    <>
      {refundModal !== null && <RefundModal />}
      {couponModal !== null && <CouponModal />}
    </>
  );
}

export default PaymentCore;
