import React from "react";
import styled from "styled-components";
import { ReactComponent as BackArrowIcon } from "../../assets/icons/back-icon.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share-icon.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/home-icon.svg";
import { useNavigate } from "react-router-dom";
import { handleShare } from "../../utils/CommonFunction";
import { customToast } from "../../utils/utils";

function ProductDetailHeader() {
  const navigate = useNavigate();

  const handleClickArrow = () => {
    navigate(-1);
  };

  const handleClickHome = () => {
    navigate("/main");
  };

  const handleClickShareIcon = () => {
    handleShare(customToast("링크가 복사되었습니다."));
  };

  return (
    <Wrapper>
      <div className="left">
        <BackArrowIcon onClick={handleClickArrow} />
      </div>
      <div className="right">
        <ShareIcon onClick={handleClickShareIcon} />
        <HomeIcon onClick={handleClickHome} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 54px;

  padding: 15px 20px;

  display: flex;
  align-items: center;

  justify-content: space-between;

  /* & .left {

  } */

  & .right {
    display: flex;
    gap: 20px;
  }
`;

export default ProductDetailHeader;
