import React, { useCallback, useState } from "react";
import useSWR from "swr";
import { api } from "../utils/api";
import useScroll from "./useScroll";

// 공용 무한스크롤 함수
function useCommonList({
  url,
  key,
  params = {},
}: {
  url: string;
  key: string;
  params?: { [key in string]: any };
}) {
  const [loading, setLoading] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  // 처음 데이터페칭
  const { data, mutate } = useSWR([url, params], async ([url, params]) => {
    setLoading(true);
    const { data } = await api.get(url, {
      params: {
        ...params,
        page: 1,
      },
    });
    setLoading(false);

    if (!data.success) return undefined;
    return data.data;
  });

  // 스크롤 맨밑에 도달 시 추가 데이터 요청 함수
  const fetchMore = useCallback(
    async (page: number) => {
      if (!data || loading || fetchMoreLoading) return;

      setFetchMoreLoading(true);
      const { data: newData } = await api.get(url, {
        params: {
          ...params,
          page,
        },
      });
      setFetchMoreLoading(false);

      if (newData.success)
        mutate(
          (oldData: any) => ({
            ...oldData,
            [key]: {
              ...oldData[key],
              data: oldData[key].data.concat(newData.data[key].data),
              current_page: page,
            },
          }),
          false
        );
    },
    [data, loading, fetchMoreLoading, mutate, key, params]
  );

  const { target } = useScroll({
    fetchMore,
    lastPage: data?.[key].last_page || 1,
    page: data?.[key].current_page || 1,
  });

  return { data, target, mutate, fetchMoreLoading, isLoading: loading };
}

export default useCommonList;
