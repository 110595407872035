import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Block } from "../../assets/icons/block-icon.svg";

export interface SummaryDigitalCardType {
  card_stock_id: number;
  id: number;
  image_url: string;
  card_sn: string;
}

interface SelectCardProps {
  card: SummaryDigitalCardType;
  onClick?: () => void;
}

function SelectCard({ card, onClick }: SelectCardProps) {
  return (
    <Wrapper onClick={onClick}>
      {card.card_sn != null &&
          <Block className="check" />
      }
      <img src={card.image_url} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  cursor: pointer;

  padding-bottom: 100%;
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 10px;

  & svg.check {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
  
  & img {
    position: absolute;

    width: 50%;
    height: 60%;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default SelectCard;
