import React from "react";
import styled from "styled-components";
import { ReactComponent as MinusIcon } from "../../assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/file-plus-icon.svg";

interface CountComponentProps {
  count: number;
  onMinus: () => void;
  onPlus: () => void;
}

function CountComponent({ count, onMinus, onPlus }: CountComponentProps) {
  return (
    <Wrapper>
      <MinusIcon width={12} height={12} onClick={onMinus} />
      <span>{count}</span>
      <PlusIcon width={12} height={12} onClick={onPlus} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100px;
  height: 36px;
  display: flex;

  justify-content: space-around;
  align-items: center;

  border-radius: 10px;
  border: 1px solid rgba(231, 231, 231, 1);
`;

export default CountComponent;
