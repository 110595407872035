import useSWR from "swr";
import { api } from "../utils/api";

export type ConfigType = {
    value: string;
};

function useConfig(name: string) {
    const { data, isLoading } = useSWR("/config?name=" + name, async (url: string) => {
        const { data } = await api.get(url);
        return data.data;
    });

    return { data, isLoading };
}

export default useConfig;
