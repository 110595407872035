import React, {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DigitalCard from "../card/DigitalCard";
import Button from "../elements/Button";
import { FixedBottomWrapper } from "../layout/CommonStyle";
import OpenCardSuccessGif from "../../assets/images/open-card-success-gif.gif";
import { Swiper, SwiperSlide } from "swiper/react";
import DigitalCardAll from "../card/DigitalCardAll";
import {Dot} from "../../utils/CommonFunction";
import { ReactComponent as CoinGreen } from "../../assets/icons/coin-green-icon.svg";

interface CardCollectionProps {
  cardList: { imageUrl: string; isRare: boolean; name: string; paybackPoint: number; cardPackId: number }[];
}

function CardCollection({ cardList }: CardCollectionProps) {
  const navigate = useNavigate();
    const [index, setIndex] = useState<number>(0);
    const swiper = useRef(null);
    const [allView, setAllView] = useState<number>(0);
  const handleSubmit = () => {
    navigate("/mypage/digital");
  };

  const handleAllView = () => {
      if(allView == 1){
          setAllView(0);
      }else {
          setAllView(1);
      }
  };

  return (
      <Wrapper>
          {/*          <TitleBox>
              <div className="title center">디지털 카드팩 개봉 완료!</div>
          </TitleBox>*/}
          <div className={allView == 1 ? "card-list-wrapper" : "none"}>
              {cardList.map((card, index) => (
                  <>
                      <DigitalCardBox key={index}>
                          <DigitalCardAll {...card} key={index}/>
                          <AllInfo>
                              <CoinWrapper><CoinGreen/>&nbsp;<span>{Dot(card.paybackPoint)}</span></CoinWrapper><br/>
                          </AllInfo><br/>
                          <BackInfo>
                              {/*
                          <p className="rare">{card.isRare ? "레어 등급" : "일반 등급"}</p>
*/}
                              <p
                                  className="name">{card.name}</p>
                          </BackInfo>
                      </DigitalCardBox>
                  </>
              ))}
          </div>
          <Detail className={allView == 1 ? "none" : ""}>
              <div>
              <DetailSwiper
                      ref={swiper}
                      //@ts-ignore
                      slidesPerView={"auto"}
                      centeredSlides={true}
                      spaceBetween={70}
                      onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
                  >
                      {cardList.map((card, index) => (
                          <StyledSlide key={"image_swiper_" + index}>
                              <DigitalCardBox key={index}>
{/*                                  <Info>
                                      <p className="rare">{card.isRare ? "레어 등급" : "일반 등급"}</p>
                                  </Info>*/}
                                  <DigitalCard {...card} key={index} cardName={card.name} paybackPoint={card.paybackPoint}/>
                              </DigitalCardBox>
                          </StyledSlide>
                      ))}
                      {cardList.length > 1 && (
                          <Paging style={{backgroundColor: "rgba(255,255,255,0.2)"}}>
                              {index + 1}/{cardList?.length}
                          </Paging>
                      )}
                  </DetailSwiper>
              </div>
          </Detail>
          <AllView>
              <Button onClick={handleAllView}>
                  {allView == 1 ? "개별 보기" : "모두 보기"}
              </Button>
          </AllView>
          <FooterWrapper>
              <Button style={{background:"#D4F648"}} styleType="line" onClick={handleSubmit}>
                  MY COLLECTION 보기
              </Button>
              <Button
                  onClick={() => {
                      navigate(`/detail/${cardList[0]?.cardPackId}`);
                  }}
              >
                  다시 뽑기
              </Button>
          </FooterWrapper>
      </Wrapper>
  );
}

const Wrapper = styled.div`
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 120px;
    background: url(${OpenCardSuccessGif});
    background-repeat: round;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .none {
        display: none;
    }
    
    & .title {
        font-family: Noto Sans KR;
        font-size: 26px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
    }
    
    & .card-list-wrapper {
        overflow-y: scroll;
        //position: fixed;
        height: 120%;
        width: 100%;
        z-index: 2;
        //padding-top: 60%;
        margin-top: 45%;
        padding: 0 42px 50px 42px;
        display: grid;
        grid-template-columns: 1.2fr 1.2fr;
        grid-gap: 15px;
    }    
    
    & .coin-div{
        position: relative;
        bottom: 10px;
        right: 30px;
    }
`;

const FooterWrapper = styled(FixedBottomWrapper)`
  background: none;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & button {
    width: calc(50% - 4px);
  }
`;
const DigitalCardBox = styled.div`
    width: 100%;
    height: 80%;
    
    & > span {
        text-align: center;
        width: 100%;
        //position: absolute;
        //margin-top: 0px;
        //left: calc(50% - 54px);
        //top: 325px;
        z-index: 2;
        padding: 8px 24px;
        border-radius: 33px;
        background: rgba(255, 255, 255, 0.2);

        font-weight: 500;
        font-size: 15px;
        line-height: 1.2;
        color: ${(props) => props.theme.color.white};
    }
`;

const BackInfo = styled.div`
  margin: 5px 0 10px 25px;
  
  & p {  
      width: 85%;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    color: #ffffff;
    &.rare {
      opacity: 0.6;
    }
    &.name {
      margin-top: 4px;
      font-weight: 700;
      line-height: 20px;
    }
  }
`;

const Info = styled.div`
  margin: 20px 0 12px 43px;
  
  & p {
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    color: #ffffff;
    &.rare {
      opacity: 0.6;
    }
    &.name {
      margin-top: 4px;
      font-weight: 700;
      line-height: 20px;
    }
  }
`;

const AllInfo = styled.div`
    margin: 10px 0 0 24px;
  
  & p {
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    color: #ffffff;
    &.rare {
      opacity: 0.6;
    }
    &.name {
      margin-top: 4px;
      font-weight: 700;
      line-height: 20px;
    }
  }
`;

const TitleBox = styled.div`
  margin-top: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
    margin-left: 20px;
  }
`;

const AllView = styled.div`
    position: absolute;
    bottom: 80px;
    width: 90%;
    
    & > button {
        height: 30px;
        font-size: 12px;
        background-color:transparent;
        color: ${(props) => props.theme.color.gray5};
        text-decoration: underline;
        border: none;
    }

    & .all-view {
        height: 30px;
        font-size: 12px;
        background-color: black;
        color: ${(props) => props.theme.color.gray5};
    }
`;

const StyledSwiper = styled(Swiper)`
    width: 100%;
    height: 100%;
    & .swiper-slide {
        max-width: 280px;
    }
`;

const StyledSlide = styled(SwiperSlide)`
  width: 70% !important;
  height: 100% !important;  
    
  & > div > div > img {
    width: 100%;
    height: 270px;  
    border-radius: 10px;
    object-fit: contain;
  }
`;

const DetailSwiper = styled(Swiper)`
    margin-top: 30px;
    width: 100%;
    height: 150%;
    & .swiper-slide {
        max-width: 280px;
    }
`;

const Detail = styled.div`
  position: fixed;
  width: 100%;
  padding-top: 25%;

  & > div {
    //position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > div > span {
    position: absolute;
    bottom: -120px;
    left: calc(50% - 54px);
    z-index: 1;
    padding: 4px 8px;
    border-radius: 33px;
    background: rgba(255, 255, 255, 0.2);

    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    color: ${(props) => props.theme.color.white};
  }
`;

const Paging = styled.div`
  position: absolute;
  bottom: 20px;
  right: 16px;
  z-index: 1;
  padding: 4px 8px;
  border-radius: 33px;
  background: rgba(0, 0, 0, 0.4);

  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: ${(props) => props.theme.color.white};
`;

const CoinWrapper = styled.div`
        float: left;
        margin-top: 2px;
        margin-bottom: 8px;
        //position: absolute;
        border: 1px solid white;
        border-radius: 5px;
        text-align: center;
        line-height: 17px;
        //bottom: -36px;
        width: 75px;
        min-width: 20px;
        height: 25px;
        background-color: black;
        & > span {
            color: white;
            font-size: 13px;
            font-weight: 400;
            vertical-align: middle;
        }

        & > svg {
            vertical-align: middle;
        }
`;

export default CardCollection;
