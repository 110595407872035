import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BigCheckIcon } from "../../assets/icons/big-check-icon.svg";
import DigitalCard from "../../components/card/DigitalCard";
import Button from "../../components/elements/Button";
import { FixedBottomWrapper } from "../../components/layout/CommonStyle";
import useOrder from "../../hooks/useOrder";

interface DigitalResultContainerProps {
  orderId: string;
}

function DigitalResultContainer({ orderId }: DigitalResultContainerProps) {
  const { data } = useOrder(orderId);
  const navigate = useNavigate();
  const digitalCard = useMemo(() => {
    return data?.order?.cards[0];
  }, [data?.order?.cards[0]]);

  const handleClick = () => {
    navigate("/mypage/digital");
  };

  return (
    <Wrapper>
      <div className="header">
        <BigCheckIcon />
        <div className="title mt-14">
          <p>구매한 상품이</p>
          <p>MY COLLECTION에 추가되었어요!</p>
        </div>
      </div>

      <div className="center">
        <div className="card-wrapper">
          <DigitalCard
            imageUrl={digitalCard?.image_url}
            isRare={digitalCard?.is_rare === 1}
          />
        </div>
      </div>

      <div className="center column mt-30">
        <p className="sub-name">{digitalCard?.category_name}</p>
        <p className="name mt-6">{digitalCard?.name}</p>
      </div>

      <FooterWrapper>
        <div className="footer">
          <Button styleType="line" onClick={handleClick}>
            카드보기
          </Button>
        </div>
      </FooterWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  background: linear-gradient(
    200.77deg,
    rgba(0, 0, 0, 0.75) 3.8%,
    #777da5 114.29%
  );
  & .header {
    padding: 40px 0;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  & .title {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
  }

  & .footer {
    background: inherit;
    padding: 20px;
  }

  & .card-wrapper {
    width: 175px;
    height: 253px;
  }

  & .sub-name {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }

  & .name {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
`;

const FooterWrapper = styled(FixedBottomWrapper)`
  background: none;
`;

export default DigitalResultContainer;
