import React, { useState } from "react";
import styled from "styled-components";
import { ProductCommentType } from "../../types/product";
import { fromNow } from "../../utils/utils";
import ProfileImage from "../common/ProfileImage";
import { ReactComponent as MoreIcon } from "../../assets/icons/more-icon.svg";
import { useSetRecoilState } from "recoil";
import { confirmModalState } from "../../recoil/atoms/modal";
import { useProductDetailAction } from "../../providers/ProductDetailProvider";
import { useNavigate, useParams } from "react-router-dom";
import { modifyCommentState } from "../../recoil/atoms/common";
import { useProductCommentAction } from "../../providers/ProductCommentProvider";
import { ReactComponent as Supporters } from "../../assets/icons/profile-supporters-icon.svg";
import {
  alertModalState,
  reportModalState,
  selectModalState,
} from "../../utils/atom";
import useUser from "../../hooks/useUser";

interface ProductCommentProps {
  comment: ProductCommentType;
  mutate: any;
  is_supporter?: number;
}

function ProductComment({
  comment,
  mutate,
  is_supporter,
}: ProductCommentProps) {
  const [isShowAll, setIsShowAll] = useState(comment.contents.length < 50);
  const setModifyComment = useSetRecoilState(modifyCommentState);
  const { cardId } = useParams();
  const { deleteComment, blockComment, reportComment } =
    useProductCommentAction();
  const navigate = useNavigate();

  const setConfirmModal = useSetRecoilState(confirmModalState);
  const setSelectModal = useSetRecoilState(selectModalState);
  const setReportModal = useSetRecoilState(reportModalState);
  const setAlertModal = useSetRecoilState(alertModalState);
  const { user } = useUser();

  const handleClickMoreView = () => setIsShowAll(true);

  const handleDelete = () => {
    const deleteCommentTrigger = () => {
      deleteComment(String(comment.id));
      mutate();
    };

    setConfirmModal({
      title: "코멘트 삭제",
      description: "코멘트를 삭제하시겠습니까?",
      onConfirm: deleteCommentTrigger,
    });
  };

  const handleModify = () => {
    setModifyComment({
      commentId: String(comment.id),
      contents: comment.contents,
    });

    navigate(`/detail/${cardId}/comment/modify`);
  };

  const handleClickOption = () => {
    if (comment.user_id === user?.user_id) {
      setSelectModal({
        options: [
          {
            text: "수정하기",
            value: "modify",
          },
          {
            text: "삭제하기",
            value: "delete",
          },
        ],
        onSelect: ({ value }) => {
          if (value === "modify") {
            handleModify();
            return;
          }

          handleDelete();
          mutate();
        },
      });

      return;
    }

    setSelectModal({
      options: [
        {
          text: "신고",
          value: "report",
        },
        {
          text: "차단",
          value: "block",
        },
      ],
      onSelect: ({ value }) => {
        if (value === "block") {
          mutate();
          blockComment(comment.user_id);
        } else {
          setReportModal({
            onSelect: async (value) => {
              const success = (await reportComment(comment.id, value)) as any;

              if (success) {
                mutate();
                setAlertModal({
                  title: "신고 완료",
                  text: "신고가 완료되었습니다.",
                  confirmText: "확인",
                });
              }
            },
          });
        }
      },
    });
  };

  const routeUserPage = () => {
    if (user?.user_id === comment.user_id) return navigate("/mypage");
    navigate(`/profile/${comment?.user_id}`);
  };

  return (
    <Wrapper>
      <div className="comment-info">
        <ProfileImage
          profileUrl={comment.profile_url}
          onClick={routeUserPage}
        />
        <div className="profile-info">
          <div className="nick_name">
            <p className="nickname" onClick={routeUserPage}>
              {comment.is_block === 1 ? "차단유저닉네임" : comment.nickname}
            </p>
            {comment?.is_supporter === 1 && <Supporters />}
          </div>
          <span className="fromnow">{fromNow(comment.created_at)}</span>
        </div>
        <div className="more-icon" onClick={handleClickOption}>
          <MoreIcon />
        </div>
      </div>
      <div className="contents-wrapper">
        {comment.is_block === 1 ? (
          <div className="contents block">차단한 회원입니다.</div>
        ) : (
          <div className="contents">
            {isShowAll ? comment.contents : comment.contents.slice(0, 50)}
            {!isShowAll && (
              <span className="more-view" onClick={handleClickMoreView}>
                ...더보기
              </span>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .comment-info {
    position: relative;
    display: flex;
  }

  & .profile-info {
    display: flex;
    flex-direction: column;

    margin-left: 8px;

    gap: 6px;
  }

  & .nickname {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;

    color: rgba(30, 30, 30, 1);
    cursor: pointer;
  }
  & .nick_name {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    & svg {
      margin-left: 6px;
    }
  }
  & .fromnow {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }

  & .more-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  & .contents-wrapper {
    position: relative;
    margin-top: 10px;
    font-family: Noto Sans KR;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
  }

  & .contents {
    font-weight: 500;
    color: rgba(58, 58, 58, 1);
  }

  & .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical;
  }

  & .more-view {
    font-weight: 400;
    color: rgba(131, 131, 131, 1);
  }

  & .block {
    color: rgba(199, 199, 199, 1);
  }
`;

export default ProductComment;
