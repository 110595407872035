import moment from "moment";
import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";
import { CardType, CARD_TYPE } from "../../utils/variables";
import EmptyComponent from "../common/EmptyComponent";
import LoadingComponent from "../common/LoadingComponent";

interface ProductRecentDealProps {
  products: {
    id: number;
    type: string;
    created_at: string;
    product_price: number;
  }[];

  isLoading: boolean;
}

function ProductRecentDeal({ products, isLoading }: ProductRecentDealProps) {
  return (
    <Wrapper>
      <div className="row top">
        <div className="first">거래 일자</div>
        <div className="second">거래 유형</div>
        <div className="third">거래 금액</div>
      </div>

      {isLoading && <LoadingComponent />}

      {products.length === 0 && (
        <EmptyComponent text="체결된 거래가 존재하지 않습니다." />
      )}

      {products.map((product) => (
        <div className="row" key={product.id}>
          <div className="first">
            {moment(product.created_at).format("yyyy-MM-DD")}
          </div>
          <div className="second">{CARD_TYPE[product.type as CardType]}</div>
          <div className="third">
            {getThousandCommaPrice(product.product_price)}원
          </div>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 17px;
  width: 100%;

  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;

  & .row {
    height: 36px;

    display: flex;
  }

  & .row > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  & .top {
    border-top: 1px solid rgba(131, 131, 131, 1);
    border-bottom: 1px solid rgba(131, 131, 131, 1);
  }

  & .first {
    width: 28.125%;
  }
  & .second {
    width: 28.125%;

    border-left: 1px solid rgba(243, 243, 243, 1);
    border-right: 1px solid rgba(243, 243, 243, 1);

    background: rgba(246, 246, 246, 0.4);
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
  }

  & .third {
    flex: 1;
  }
`;

export default ProductRecentDeal;
