import React from "react";
import styled from "styled-components";
import Divide from "../../components/common/Divide";
import ProductImages from "../../components/detail/ProductImages";
import ProductInfo from "../../components/detail/ProductInfo";
import ProductPriceInfo from "../../components/detail/ProductPriceInfo";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import ProductExplanationContainer from "./ProductExplanationContainer";

function DigitalPackageContainer() {
  const product = useProductDetail() as any;

  return (
    <Wrapper>
      <ProductImages images={product.card_images} />
      <ProductInfo info={product.card} />
      <ProductPriceInfo
        className="mt-28"
        price={product?.card?.price || 0}
        amount={{
          total: product.total_amount,
          open: product.total_amount - product.remain_amount,
          remain: product.remain_amount,
        }}
      />

      <Divide margin={20} />
      <ProductExplanationContainer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 100px;
`;

export default DigitalPackageContainer;
