import React, { useMemo } from "react";
import styled from "styled-components";
import PageLoadingComponent from "../../../components/common/PageLoadingComponent";
import DigitalPackGiftContainer from "../../../containers/result/DigitalPackGiftContainer";
import DigitalPackResultContainer from "../../../containers/result/DigitalPackResultContainer";
import DigitalResultContainer from "../../../containers/result/DigitalResultContainer";
import GoodsResultContainer from "../../../containers/result/GoodsResultContainer";

import useOrder from "../../../hooks/useOrder";

interface Props {
  orderId: string;
}

function ResultSuccess({ orderId }: Props) {
  const { data, isLoading } = useOrder(orderId);

  const orderType = useMemo(() => {
    if (!data) return;
    return data?.order_card_type;
  }, [data?.order_card_type]);

  const gift = useMemo(() => {
    if (!data) return;
    return data?.order?.gift;
  }, [data?.order?.gift]);

  if (isLoading) return <PageLoadingComponent />;
  if (!data) return <div>{data?.alert || "ERROR"}</div>;

  if (gift == 1) {
    return (
      <Wrapper>
        <DigitalPackGiftContainer orderId={orderId} />
      </Wrapper>
    );
  }

  if (orderType === "digital_pack") {
    return (
      <Wrapper>
        <DigitalPackResultContainer orderId={orderId} />
      </Wrapper>
    );
  }

  if (orderType === "digital") {
    return (
      <Wrapper>
        <DigitalResultContainer orderId={orderId} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <GoodsResultContainer orderId={orderId} type={orderType} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: scroll;
`;

export default ResultSuccess;
