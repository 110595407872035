import React from "react";
import styled from "styled-components";
import Divide from "../../components/common/Divide";
import ProductImages from "../../components/detail/ProductImages";
import ProductInfo from "../../components/detail/ProductInfo";
import ProductRecentPrice from "../../components/detail/ProductRecentPrice";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import ProductCommunitiesContainer from "./ProductCommunitiesContainer";
import ProductExplanationContainer from "./ProductExplanationContainer";
import ProductMarketPriceContainer from "./ProductMarketPriceContainer";
import ProductReviewsContainer from "./ProductReviewsContainer";
import ProductRelationContainer from "./ProductRelationContainer";
import ProductRelation from "../../components/detail/ProductRelation";

interface GoodsPackageContainerProps {}

function GoodsPackageContainer({}: GoodsPackageContainerProps) {
  const product = useProductDetail();

  return (
    <Wrapper>
      <ProductImages images={product.card_images} />
      <ProductRelation images={product.relation_card_images} />
      <ProductInfo info={product.card} />
      <ProductRecentPrice
        recentPrice={product.last_deal_price}
        recentGap={product.last_deal_gap}
        lowCardStock={product?.low_card_stock?.price ?? ''}
        highCardStock={product?.high_card_stock?.price ?? ''}
        is_pack={product.card.is_pack}
        is_digital={product.card.is_digital}
      />
      <Divide margin={20} />
      <ProductMarketPriceContainer />
      <ProductExplanationContainer />
      {product.communities.length > 0 && (
        <ProductCommunitiesContainer className="mt-40" />
      )}
      <ProductReviewsContainer />
      <div style={{marginBottom:"20px"}} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 70px;
`;

export default GoodsPackageContainer;
