import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import styled from "styled-components";
import { refundModalState } from "../../../recoil/atoms/modal";
import {
  convertListVariables,
  onChangeNumberType,
  onChangeStringType,
} from "../../../utils/utils";
import { BANK_VARIABLES } from "../../../utils/variables";
import ModalHeader from "../../common/ModalHeader";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import Select from "../../elements/Select";
import { CommonBottomModalContainer } from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";

function RefundModal() {
  const modal = useRecoilValue(refundModalState);
  const reset = useResetRecoilState(refundModalState);

  const [bank, setBank] = useState(modal?.defaultValue?.bank || "");
  const [account, setAccount] = useState(modal?.defaultValue?.account || "");
  const [owner, setOwner] = useState(modal?.defaultValue?.owner || "");
  const options = convertListVariables(BANK_VARIABLES).map(
    ({ value, label }) => ({ value, text: label })
  );

  const handleClose = () => {
    reset();
  };

  const handleChangeBank: (value: {
    value: string;
    text?: string | undefined;
  }) => void = ({ value, text }) => {
    setBank(value);
  };

  const handleChangeAccount = (e: any) => {
    const value = e.target.value;
    if (isNaN(Number(value.slice(-1)))) return;
    if (value.length > 55) return;
    setAccount(value);
  };

  const handleChangeOwnerName = onChangeStringType((value) => {
    if (value.length > 50) return;

    setOwner(value);
  });

  const isValid = useMemo(() => {
    return bank && account && owner;
  }, [bank, account, owner]);

  const handleSubmit = () => {
    if (!modal) return;

    modal.onSubmit({ bank, account, owner });

    handleClose();
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (!modal) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper>
        <ModalHeader title="환불 계좌 설정" onClose={reset} />
        <div className="container">
          <h4 className="title">계좌정보</h4>

          <div className="mt-14">
            <Select
              value={BANK_VARIABLES[bank]}
              className="line"
              options={options}
              onChange={handleChangeBank}
              placeholder="은행 선택"
              borderRadius="10px 10px 0 0"
              borderBottom="0"
            />
            <Input
              label="계좌번호"
              placeholder="‘-’를 제외하고 입력"
              borderRadius="0"
              borderBottom="0"
              value={account}
              onChange={handleChangeAccount}
            />
            <Input
              label="예금주명"
              placeholder="예금주명 입력"
              borderRadius="0 0 10px 10px"
              value={owner}
              onChange={handleChangeOwnerName}
            />
          </div>
        </div>

        <p className="reference-text mt-10">
          판매자 및 관리자의 결제 취소시 환불받을 계좌정보를 알려주세요.
        </p>
        <p className="reference-text">
          수집된 계좌정보는 환불 목적 외 이용되지 않습니다.
        </p>

        <div className="footer">
          <Button disabled={!isValid} onClick={handleSubmit}>
            완료
          </Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  & .container {
    margin-top: 20px;
  }

  & .footer {
    margin-top: 20px;
    padding: 15px 0;
  }

  & .reference-text {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(131, 131, 131, 1);
  }
`;

export default RefundModal;
