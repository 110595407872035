import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import PageLoadingComponent from "../../components/common/PageLoadingComponent";
import Title from "../../components/common/Title";
import MarketPriceChart from "../../components/detail/market-price/MarketPriceChart";
import SelectTab from "../../components/detail/SelectTab";
import { convertListVariables } from "../../utils/utils";
import { MONTH_VARIABLES } from "../../utils/variables";

function ProductMarketPriceContainer() {
  const { cardId } = useParams();
  const { data, isLoading } = useSWR(`/card/deal?card_id=${cardId}`);

  const [selectedMonth, setSelectedMonth] = useState<string>("1");

  // 월(month) 변경
  const handleChangeMonth = (month: string) => {
    setSelectedMonth(month);
  };

  if (isLoading) {
    return (
      <Wrapper>
        <Title title="거래 시세" />
        <PageLoadingComponent />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title title="거래 시세" />
      <SelectTab
        selectedValue={selectedMonth}
        list={convertListVariables(MONTH_VARIABLES)}
        onChange={handleChangeMonth}
      />
      <MarketPriceChart products={data?.order_cards || []} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

export default ProductMarketPriceContainer;
