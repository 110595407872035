import styled from "styled-components";
import { ReactComponent as HeartEmptyIcon } from "../../assets/icons/heart-empty2-icon.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart-active-icon.svg";
import { BasicContainer, FixedBottomWrapper } from "../layout/CommonStyle";
import Button from "../elements/Button";
import {
  useProductDetail,
  useProductDetailAction,
} from "../../providers/ProductDetailProvider";
import { renderPrice } from "../../utils/utils";
import { useSetRecoilState } from "recoil";
import { digitalPackagePaymentModalState } from "../../recoil/atoms/modal";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";

function DigitalPacakgeFooter() {
  const { low_card_stock, card, open_amount, total_amount, remain_amount } =
    useProductDetail() as any;
  const { toggleLike } = useProductDetailAction();
  const setPaymentModal = useSetRecoilState(digitalPackagePaymentModalState);
  const { checkLogin } = useUser();
  const { cardId } = useParams();

  // 로그인 check 후 정상동작 시에 결제모달 노출
  const handleClickButton = checkLogin((gift : number) => {
    setPaymentModal({
      stockId: cardId as string,
      title: card.name,
      price: card.price,
      gift: gift,
      amount: {
        open: open_amount,
        total: total_amount,
        remain: remain_amount,
      },
    });
  });

  // 로그인 check 후 정상동작 시에 카드 관심상품 등록 및 해제
  const handleClickLike = checkLogin(() => {
    toggleLike(card.id);
  });

  return (
    <FixedBottomWrapper>
      <Wrapper>
        <div className="heart-wrapper">
          {card.my_like === 1 ? (
            <HeartIcon className="heart-icon" onClick={handleClickLike} />
          ) : (
            <HeartEmptyIcon className="heart-icon" onClick={handleClickLike} />
          )}
        </div>
        <div className="buttons">
          <Button
            direction="column"
            onClick={() => {
              handleClickButton(1)
            }}
          >
            <p>선물하기</p>
            <p className="button-text">
              {renderPrice(card.price)}
            </p>
          </Button>
          <Button
            styleType="sub"
            direction="column"
            onClick={() => {
              handleClickButton(0)
            }}
          >
            <p>구매하기</p>
            <p className="button-text sub">{renderPrice(card.price)}</p>
          </Button>
        </div>
      </Wrapper>
    </FixedBottomWrapper>
  );
}

const Wrapper = styled(BasicContainer)`
  gap: 17px;

  & .heart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .heart-icon {
    width: 28px;
    height: 28px;
  }

  & .button-wrapper {
    flex: 1;
  }

  & .buttons {
    flex: 1;
    display: flex;
    gap: 5px;
  }
  
  & .button-text {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(243, 243, 243, 1);
  }

  & .button-text.sub {
    color: rgba(58, 58, 58, 1);
  }
`;

export default DigitalPacakgeFooter;
