import styled from "styled-components";

export const FixedBottomWrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
`;

export const BasicContainer = styled.div`
  width: 100%;
  max-width: 840px;
  margin: 0 auto;

  padding: 10px 20px;

  width: 100%;
  display: flex;
`;

export const CommonBottomModalContainer = styled.div`
  border-radius: 20px 20px 0px 0px;

  padding: 0 20px;

  width: 100%;

  background: #fff;
`;

export const CommonPageWrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
`;

export const CardLabel = styled.p`
  font-family: Noto Sans KR;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(172, 172, 172, 1);
`;

export const CardPrice = styled.h4`
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;
