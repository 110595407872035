import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { generateMedia } from "styled-media-query";

export const customMedia = generateMedia({
  desktop: "1200px",
  tablet: "768px",
  mobile: "360px",
});

export const GlobalStyles = createGlobalStyle`
    ${reset}

    *,
    *::after,
    *::before {
        outline: none;
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        border: 0;
        font-family: 'Noto Sans KR' !important;
        word-break: break-all;

        -webkit-tap-highlight-color: transparent !important;
    }


    *:not(input, textarea) {
        user-select: none;
    }

    html,
    body {
        overflow: hidden;
        width: 100%;
        height: 100%;
        touch-action: pan-y;

        *::-webkit-scrollbar {
            display: none;
        }
    }

    a,
    button {
        display: inline-block;
        cursor: pointer;
        background: none;
        border: 0;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox  */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    svg {
        cursor: pointer;
    }

    .mobile-hidden {
        ${customMedia.lessThan("tablet")`
        display : none;
        `}
    }

    .tablet-hidden {
        ${customMedia.greaterThan("tablet")`
        display : none;
        `}
    }

    a {
        text-decoration: none;
        color : inherit;
    }

    b {
        font-weight: 700;
    }

    
    .ql-snow .ql-editor * {
        font-weight: revert !important;
        font-style: revert !important;
    }

    .mt-9 {
      margin-top : 9px;
    }

    .mt-20 {
      margin-top : 20px;
    }

    .mt-14 {
      margin-top : 14px;
    }

    .mt-16 {
      margin-top : 16px;
    }

    .mt-18 {
      margin-top : 18px;
    }

    .mt-6 {
      margin-top : 6px;
    }

    .mt-8 {
      margin-top : 8px;
    }

    .mt-10 {
      margin-top : 10px;
    }

    .mt-26 {
      margin-top : 26px;
    }

    .mt-28 {
      margin-top : 28px;
    }

    .mt-30{
      margin-top : 30px;
    }

    .mt-40 {
      margin-top : 40px;
    }

    .mt-60{
      margin-top : 60px;
    }

    .ml-4 {
      margin-left : 4px;
    }

    .text-gray1 {
      color: rgba(30, 30, 30, 1);
    }
    .text-gray5 {
      color: rgba(172, 172, 172, 1);
    }

    .title {
      font-family: Noto Sans KR;
      font-size: 15px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(30, 30, 30, 1);
    }

    .center {
      display: flex;
      align-items :center;
      justify-content: center;
    }

    .column {
      flex-direction:column;
    }

    .ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      white-space: nowrap;
    }
    .pointer {
      cursor: pointer
    }
    .relative {
      position :relative;
    }
`;
