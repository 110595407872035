import React from "react";
import styled, { css } from "styled-components";
import { SelectType } from "../../types/types";
import { useSetRecoilState } from "recoil";
import { selectModalState } from "../../utils/atom";

import { ReactComponent as Arrow } from "../../assets/icons/select-arrow-icon.svg";

const Select = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  className,
  borderRadius,
  borderBottom,
}: SelectType) => {
  const setSelectModal = useSetRecoilState(selectModalState);

  const handleClick = () => {
    setSelectModal({
      options: options,
      value: value ? value : "",
      onSelect: (value) => onChange(value),
    });
  };

  return (
    <Label
      onClick={handleClick}
      className={className ? className : ""}
      borderRadius={borderRadius}
      borderBottom={borderBottom}
    >
      <p>{label}</p>
      <SelectBox>
        <StyleSelect className={!value || value === "" ? "placeholder" : ""}>
          {!value || value === "" ? placeholder : value}
        </StyleSelect>
        <Arrow />
      </SelectBox>
    </Label>
  );
};

export default Select;

const StyleSelect = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};
  cursor: pointer;

  &.placeholder {
    color: ${(props) => props.theme.color.gray6};
  }
`;

const SelectBox = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const Label = styled.div<{ borderRadius?: string; borderBottom?: string }>`
  position: relative;
  display: block;
  padding: 11px 13px 9px;
  border: 1px solid ${(props) => props.theme.color.white};

  &.line {
    border: 1px solid rgba(231, 231, 231, 1);

    ${(props) =>
      props.borderBottom &&
      css`
        border-bottom: ${props.borderBottom};
      `}
  }

  & > p {
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray4};
    margin-bottom: 4px;
  }

  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "10px"};
`;
