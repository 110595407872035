import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteCommentMutation,
  modifyCommentMutation,
  registerCommentMutation,
} from "../apis/common";
import { ProductCommentType } from "../types/product";
import { api } from "../utils/api";
import { customToast } from "../utils/utils";
import {
  useProductDetail,
  useProductDetailAction,
} from "./ProductDetailProvider";

export type DeleteCommentType = (commentId: string) => Promise<void>;
export type ModifyCommentType = ({
  commentId,
  contents,
}: {
  commentId: number | string;
  contents: string;
}) => Promise<void>;
export type RegisterCommentType = (contents: string) => Promise<void>;

type ProductDetailValueType = {
  comments: ProductCommentType[];
};

type ProdcutDetailActionType = {
  deleteComment: DeleteCommentType;
  modifyComment: ModifyCommentType;
  registerComment: RegisterCommentType;
  reportComment: (commentId: number | string, type: string) => Promise<void>;
  blockComment: (userId: number | string) => Promise<void>;
};

const ProductCommentValueContext = createContext<
  ProductDetailValueType | undefined
>(undefined);
const ProductCommentActionContext = createContext<
  ProdcutDetailActionType | undefined
>(undefined);

interface Props {
  children: React.ReactNode;
  cardId: string;
}

function ProductCommentProvider({ children, cardId }: Props) {
  const navigate = useNavigate();
  const { card_comments } = useProductDetail();
  const { mutate } = useProductDetailAction();
  const registerComment: RegisterCommentType = async (contents: string) => {
    const { data } = await registerCommentMutation({
      cardId: cardId as string,
      contents,
    });
    if (data.success) {
      customToast("코멘트가 등록되었습니다.");
      navigate(-1);
      mutate();
    } else {
      window.alert(data.alert);
    }
  };

  const deleteComment: DeleteCommentType = async (commentId) => {
    const { data } = await deleteCommentMutation(commentId);

    if (data.success) {
      customToast("코멘트가 삭제되었습니다.");
    } else {
      window.alert(data.alert);
    }
  };

  const modifyComment: ModifyCommentType = async ({ commentId, contents }) => {
    const { data } = await modifyCommentMutation({ commentId, contents });
    if (data.success) {
      customToast("코멘트가 수정되었습니다.");
      navigate(-1);
    } else {
      window.alert(data.alert);
    }
  };

  const blockComment = async (userId: number | string) => {
    const { data } = await api.post("/block", {
      type: 1,
      target_user_id: userId,
    });

    if (data.success) {
      customToast("차단되었습니다.");
    } else {
      window.alert(data.alert);
    }
  };

  const reportComment = async (commentId: number | string, type: string) => {
    const { data } = await api.post("/card/comment/report", {
      comment_id: commentId,
      report_type: type,
    });

    if (data.success) {
    } else {
      window.alert(data.alert);
    }

    return data.success;
  };

  const values = useMemo(() => {
    return {
      comments: card_comments,
    };
  }, [card_comments]);

  const actions: ProdcutDetailActionType = useMemo(() => {
    return {
      deleteComment,
      modifyComment,
      registerComment,
      reportComment,
      blockComment,
    };
  }, [
    deleteComment,
    modifyComment,
    registerComment,
    reportComment,
    blockComment,
  ]);

  return (
    <ProductCommentValueContext.Provider value={values}>
      <ProductCommentActionContext.Provider value={actions}>
        {children}
      </ProductCommentActionContext.Provider>
    </ProductCommentValueContext.Provider>
  );
}

export function useProductCommentValue() {
  const values = useContext(ProductCommentValueContext);

  if (!values) throw new Error("not wrapped with ProductCommentProvider");

  return values;
}

export function useProductCommentAction() {
  const actions = useContext(ProductCommentActionContext);
  if (!actions) throw new Error("not wrapped with ProductCommentProvider");
  return actions;
}

export default ProductCommentProvider;
