import React, { useEffect, useMemo, useState } from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import styled from "styled-components";
import useSWR from "swr";
import { goodsPackagePaymentModalState } from "../../../recoil/atoms/modal";
import SellerProfile from "../../card/SellerProfile";
import Divide from "../../common/Divide";
import PaymentInfo from "../../common/PaymentInfo";
import Button from "../../elements/Button";
import { CommonBottomModalContainer } from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/file-plus-icon.svg";
import { customToast, sellerSubText } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { addToCard } from "../../../apis/common";
import PageLoadingComponent from "../../common/PageLoadingComponent";
import {api} from "../../../utils/api";
import {twoButtonModalState} from "../../../utils/atom";

function GoodsPackagePaymentModal() {
  const [modal, setModal] = useRecoilState(goodsPackagePaymentModalState);
  const [amount, setAmount] = useState(1);
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

  const { data, isLoading } = useSWR(
    `/card/stock/detail?card_stock_id=${modal?.stockId}`
  );
  const navigate = useNavigate();

  const handleClose = () => {
    setModal(null);
  };

  const handleClickAmount = (type: "plus" | "minus") => () => {
    if (!modal) return;

    if (type === "plus") {
      if (data.card_stock.remain_amount <= amount) {
        customToast("최대 재고 수량을 초과했어요.");
        return;
      }
      setAmount((old) => old + 1);
    } else {
      if (amount === 1) {
        customToast("수량은 1개 이상 선택할 수 있어요.");
        return;
      }

      setAmount((old) => old - 1);
    }
  };

  const deliveryPrice = useMemo(() => {
    if (!data) return 0;

    if (data.seller_info.use_free_delivery_fee === 0) {
      return data.seller_info.basic_delivery_fee;
    }

    if (
      data.seller_info.free_delivery_fee_standard >
      data.card_stock.price * amount
    ) {
      return data.seller_info.basic_delivery_fee;
    }
    return 0;
  }, [data, amount]);

  const handleBasket = () => {
    if (!modal) return;
    addToCard(modal.stockId, ()  => {
      customToast("장바구니에 추가되었습니다.");
      handleClose();
    },(cartId: number, amount: number)  => {
      setTwoButtonModal({
        title: "장바구니 추가",
        text: "장바구니에 이미 존재하는 상품입니다.<br/>추가 하시겠습니까?",
        onConfirm: () => handleAmount(cartId, amount),
      })
    }, amount, 1);
  };

  const handleAmount = async (id: number, amount: number) => {
    const formData = new FormData();
    formData.append("cart_id", String(id));
    formData.append("amount", String(amount));
    formData.append("type", "add");

    const {
      data: { success, alert, data },
    } = await api.post("/cart/amount", formData);

    if (success) {
      customToast("추가 완료");
    } else {
      customToast(alert);
    }
  };

  const handleBuy = () => {
    if (!modal?.stockId) return;

    localStorage.setItem(
      "state",
      JSON.stringify({
        stockIds: [modal.stockId],
        amounts: {
          [modal.stockId]: amount,
        },
      })
    );
    navigate(`/payment`);

    handleClose();
  };

  useEffect(() => {
    return () => {
      setModal(null);
    };
  }, []);

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (!modal) return null;
  if (isLoading) return <PageLoadingComponent />;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper>
        <div className="header">
          <SellerProfile
            userId={data.seller_info.id}
            nickname={data.seller_info.nickname}
            subText={sellerSubText({
              useFree: data.seller_info.use_free_delivery_fee === 1,
              freeStandard: data.seller_info.free_delivery_fee_standard,
            })}
            profileImage={data.seller_info.profile_url}
          />
        </div>
        <div className="contents">
          <Divide />
          <div className="card-info">
            <div>
              <div className="card-name">{data.card_stock.name}</div>
              <div className="amount-info">
                판매중인 수량 {data.card_stock.remain_amount}
              </div>
            </div>
            <div className="count-wrapper">
              <MinusIcon
                width={12}
                height={12}
                onClick={handleClickAmount("minus")}
              />
              <span>{amount}</span>
              <PlusIcon
                width={12}
                height={12}
                onClick={handleClickAmount("plus")}
              />
            </div>
          </div>
          <Divide />
          <PaymentInfo
            className="mt-16"
            price={data.card_stock.price * amount}
            deliveryPrice={deliveryPrice}
          />
        </div>

        <div className="footer">
          <Button styleType="line" onClick={handleBasket}>
            장바구니
          </Button>
          <Button onClick={handleBuy}>구매하기</Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  & .header {
    padding-top: 20px;
    padding-bottom: 15px;
  }

  & .footer {
    display: flex;
    padding: 15px 0;
    gap: 8px;

    margin-top: 10px;
  }
  & .card-info {
    padding: 20px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .count-wrapper {
    width: 100px;
    height: 36px;
    display: flex;

    justify-content: space-around;
    align-items: center;

    border-radius: 10px;
    border: 1px solid rgba(231, 231, 231, 1);
  }

  & .card-name {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(112, 112, 112, 1);
  }
  & .amount-info {
    margin-top: 8px;
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(131, 131, 131, 1);
  }
`;
export default GoodsPackagePaymentModal;
