import useSWR from "swr";
import { api } from "../utils/api";

export type CouponType = {
  id: number;
  name: string;
  type: string;
  price: number;
  max_price: number;
  end_date: string;
};

function useCouponList() {
  const { data, isLoading } = useSWR("/user/coupon", async (url: string) => {
    const { data } = await api.get(url);
    return data.data;
  });

  return { data, isLoading };
}

export default useCouponList;
