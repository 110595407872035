import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import useSWR from "swr";
import { confirmModalState } from "../recoil/atoms/modal";
import { api } from "../utils/api";
import auth from "../utils/auth";
import {alertModalState} from "../utils/atom";

type AuthType = {
  is_seller: 1 | 0;
  is_supporter: 1 | 0;
  user_id: number;
};

// user 데이터 hooks함수
function useUser(): {
  user: AuthType | undefined;
  checkAuth: any;
  checkLogin: any;
  isLoading: boolean;
} {
  const { data, isLoading } = useSWR("userType", async () => {
    if (!auth.getToken()) return undefined;
    const { data } = await api("/user/type");
    if (!data.success) return undefined;
    return data.data;
  });

  const setConfirmModal = useSetRecoilState(confirmModalState);
  const navigate = useNavigate();
  const setAlertModal = useSetRecoilState(alertModalState);

  // 판매등록과 로그인유무를 체크 후 정상동작시에 callback함수를 실항함는 함수
  const checkAuth = (callback: Function) => (e: any) => {
    if (!data) {
      setConfirmModal({
        title: "로그인 필요",
        description:
          "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
        onConfirm: () => navigate("/"),
      });
      return;
    }

    if(data?.seller_status === 2){
      setAlertModal({
        title: "판매 회원 등록 대기",
        text: "현재 판매 회원 등록 신청 대기중입니다.<br/>공휴일 제외 영업일 기준 2~3일 소요됩니다!"
      });
      return;
    }

    if (!data.is_seller) {
      setConfirmModal({
        title: "판매 회원 등록 필요",
        description:
          "판매 회원으로 등록 후<br/>상품을 판매할 수 있습니다.<br/>판매 회원으로 등록하시겠습니까?",
        onConfirm: () => navigate("/user/submit/sale/chk"),
      });
      return;
    }

    callback(e);
  };

  // 로그인 유무를 체크 후 정상동작시에 callback함수를 실행하는 함수
  const checkLogin = (callback: Function) => (e: any) => {
    if (!data) {
      setConfirmModal({
        title: "로그인 필요",
        description:
          "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
        onConfirm: () => navigate("/"),
      });
      return;
    }

    callback(e);
  };

  return {
    user: data,
    checkAuth,
    checkLogin,
    isLoading,
  };
}

export default useUser;
