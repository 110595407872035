import React, { useState, useEffect, useMemo } from "react";
import SaleModal from "../../components/modal/SaleModal";
import { useSetRecoilState } from "recoil";
import {
  saleModalState,
  termModalState,
  toastModalState,
} from "../../utils/atom";
import { PickListType } from "../../types/api";
import { useNavigate } from "react-router-dom";
import BackWrapper from "../../components/wrapper/BackWrapper";
import SaleProductList from "../../components/list/SaleProductList";
import { SaleProductType } from "../../types/types";
import Button from "../../components/elements/Button";
import styled from "styled-components";
import CheckBox from "../../components/elements/CheckBox";
import { api } from "../../utils/api";
import { toNumber } from "../../utils/CommonFunction";
import auth from "../../utils/auth";
import { customToast } from "../../utils/utils";
import PageLoadingComponent from "../../components/common/PageLoadingComponent";

const Product = () => {
  const navigate = useNavigate();

  const setSaleModal = useSetRecoilState(saleModalState);
  const setTermModal = useSetRecoilState(termModalState);
  const setToastModal = useSetRecoilState(toastModalState);

  const [check, setCheck] = useState<boolean>(false);
  const [product, setProduct] = useState<PickListType[]>([]);
  const [type, setType] = useState(0);
  const [option, setOption] = useState<SaleProductType[]>([]);

  useEffect(() => {
    setSaleModal({
      onClose: () => navigate(-1),
      onConfirm: (item, type) => {
        let arr2 = [...option];
        setType(type);
        if (type === 0) {
          //@ts-ignore
          item.map((item, i) => {
            arr2.push({
              type: type,
              prices: "",
              gradings: "",
              levels: "",
              contents: "",
              conditions: "",
              images: [],
            });
          });
        } else {
          //@ts-ignore
          item.map((item, i) => {
            arr2.push({ type: type, prices: "", amounts: "", images: [] });
          });
        }
        // @ts-ignore
        setProduct(item);
        setOption(arr2);
      },
      first: true,
    });
  }, []);

  const handleAdd = () => {
    setSaleModal({
      onConfirm: (item, type) => {
        let check = true;

        for (const prod of product) {
          // @ts-ignore
          if(item.length > 0){
            // @ts-ignore
            for(let i=0; i < item.length; i++){
              // @ts-ignore
              if (prod.id === item[i].id) {
                check = false;
                break;
              }
            }
          }
          //if (prod.id === item.id) check = false;
        }

        if (check) {
          // @ts-ignore
          let arr = [...product, ...item];
          let arr2 = [...option];

          //arr.push(item);

          if (type === 0) {
            //@ts-ignore
            item.map((item, i) => {
              arr2.push({
                type: type,
                prices: "",
                gradings: "",
                levels: "",
                contents: "",
                conditions: "",
                images: [],
              });
            });
          } else {
            //@ts-ignore
            item.map((item, i) => {
              arr2.push({ type: type, prices: "", amounts: "", images: [] });
            });
          }

          // @ts-ignore
          setProduct(arr);
          setOption(arr2);
        } else setToastModal({ text: "동일한 상품은 선택 불가능합니다." });
      },
    });
  };

  const handleTerm = (text: string) => {
    setTermModal({ type: text, title: "판매 약관 동의" });
  };

  const [isFetchLoading, setIsFetchLoading] = useState(false);

  const handleClick = async () => {
    if (isFetchLoading) return;

    const formData = new FormData();
    for (let i = 0; i < product.length; i++) {
      formData.append("card_ids[]", String(product[i].id));
      formData.append(`prices[${product[i].id}]`, toNumber(option[i].prices));
      formData.append(`types[${product[i].id}]`, "goods");
      formData.append(`etcs[${product[i].id}]`, String(option[i].contents));

      if (option[i].type === 0) {
        formData.append(
          `conditions[${product[i].id}]`,
          //@ts-ignore
          option[i].conditions === "" ? "하자없음" : option[i].conditions
        );
        formData.append(
            `gradings[${product[i].id}]`,
            //@ts-ignore
            option[i].gradings === "" ? "일반" : option[i].gradings
        );
        formData.append(
            `levels[${product[i].id}]`,
            //@ts-ignore
            (option[i].levels === "" && (option[i].gradings === "일반" || option[i].gradings === "")) ? "5" :
                (option[i].levels === "" && (option[i].gradings !== "" && option[i].gradings !== "일반")) ? "10" :
                option[i].levels
        );
      } else {
        formData.append(
          `amounts[${product[i].id}]`,
          //@ts-ignore
          toNumber(option[i].amounts)
        );
      }

      //@ts-ignore
      for (let j = 0; j < option[i].images.length; j++) {
        formData.append(
          `images[${product[i].id}][${j}]`,
          //@ts-ignore
          option[i].images[j].file
        );
      }
    }

    setIsFetchLoading(true);
    const {
      data: { success, alert, data },
    } = await api.post("/card/register", formData);
    setIsFetchLoading(false);

    if (success) {
      customToast("판매 등록이 완료 되었습니다.");
      auth.setRegisterCard("true");
      navigate("/order/sale", { replace: true });
    } else {
      window.alert(alert);
    }
  };

  const isValid = useMemo(() => {
    if (!check) return false;

    if (
      option.some((optionItem) => {
        if (!optionItem.prices) return true;
        if (parseInt(toNumber(optionItem.prices)) < 500) return true;
        if (type === 0 && optionItem?.images?.length === 0) return true;
        return false;
      })
    ) {
      return false;
    }

    return true;
  }, [check, option, type]);

  return (
    <>
      <BackWrapper
        title="판매 등록"
        buttonText="등록하기"
        disable={!isValid}
        onClick={handleClick}
      >
        {product?.map((item, i: number) => (
          <SaleProductList
            key={"sale_submit_list" + i}
            item={item}
            product={product}
            setProduct={setProduct}
            option={option}
            setOption={setOption}
            index={i}
            onChange={() => {
              setSaleModal({
                onConfirm: (item, type) => {
                  let check = true;

                  for (const prod of product) {
                    // @ts-ignore
                    if(item.length > 0){
                      // @ts-ignore
                      for(let i=0; i < item.length; i++){
                        // @ts-ignore
                        if (prod.id === item[i].id) {
                          check = false;
                          break;
                        }
                      }
                    }
                    //if (prod.id === item.id) check = false;
                  }

                  if (check) {
                    // @ts-ignore
                    let arr = [...product, ...item];
                    let arr2 = [...option];

                    //arr[i] = item;
                    if (type === 0)
                      arr2[i] = {
                        type: type,
                        prices: "",
                        gradings: "",
                        levels: "",
                        contents: "",
                        conditions: "",
                        images: [],
                      };
                    else
                      arr2[i] = {
                        type: type,
                        prices: "",
                        amounts: "",
                        images: [],
                      };

                    // @ts-ignore
                    setProduct(arr);
                    setOption(arr2);
                  } else
                    setToastModal({ text: "동일한 상품은 선택 불가능합니다." });
                },
              });
            }}
          />
        ))}
        <BottomBox>
          <Button styleType="line" onClick={handleAdd}>
            + 추가 등록
          </Button>
          <CheckBox checked={check} onChange={() => setCheck(!check)}>
            <CheckText>
              (필수) 판매 약관 동의
              <CheckButton
                type="button"
                onClick={() => handleTerm("판매 약관")}
              >
                전체보기
              </CheckButton>
            </CheckText>
          </CheckBox>
        </BottomBox>
      </BackWrapper>
      <SaleModal />

      {isFetchLoading && <PageLoadingComponent />}
    </>
  );
};

export default Product;

export const CheckButton = styled.button`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-decoration-line: underline;
  color: ${(props) => props.theme.color.gray4};
`;

export const CheckText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;

  color: ${(props) => props.theme.color.gray1};
`;

export const BottomBox = styled.div`
  padding: 0 20px;

  & > button {
    margin-bottom: 40px;
  }
`;
