import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { waybillModalState } from "../../recoil/atoms/modal";
import { onChangeStringType } from "../../utils/utils";
import ModalHeader from "../common/ModalHeader";
import Button from "../elements/Button";
import Input from "../elements/Input";
import Select from "../elements/Select";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";
import axios from "axios";
import { api } from "../../utils/api";
import {toNumber} from "../../utils/CommonFunction";

interface CompanyType {
  Code: string;
  International: "true" | "false";
  Name: string;
}

function WaybillModal() {
  const [modal, setModal] = useRecoilState(waybillModalState);
  const [selected, setSelected] = useState({ value: "", text: "" });
  const [number, setNumber] = useState("");

  const { data, isLoading } = useSWR("companylist", async () => {
    const { data: keyData } = await api.get("/sweettracker");

    if (!keyData.success) {
      window.alert(keyData.alert);
      return;
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_PARCEL_SERVICE_URL}/api/v1/companylist`,
      {
        params: {
          t_key: keyData?.data?.key,
        },
      }
    );

    return data;
  });

  const options = data?.Company.filter((company: CompanyType) => {
    return company.International === "false";
  }).map((company: CompanyType) => ({
    text: company.Name,
    value: company.Code,
  }));

  const handleClose = () => {
    setModal(null);
  };

  const handleChangeNumber = onChangeStringType((value) => {
    setNumber(toNumber(value));
  });

  const handleSubmit = async () => {
    if (!modal) return;

    const { data } = await api.post("/order/delivery", {
      order_card_id: modal.orderId,
      parcel_service: selected.value,
      tracking_number: number,
    });

    if (data.success) {
      handleClose();
      modal?.onRegister && modal?.onRegister();
    } else {
      window.alert(data.alert);
    }
  };

  const isValid = useMemo(() => {
    return selected.value && number;
  }, [selected.value, number]);

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;
  if (isLoading) return <div>loading</div>;
  return (
    <ModalWrapper position="full">
      <Wrapper>
        <ModalHeader title="운송장 등록" onClose={handleClose} />

        <div className="mt-10">
          <h1>상품을 발송한 운송장</h1>
          <h1>정보를 입력해주세요.</h1>
        </div>

        <div className="mt-26">
          <Select
            label="택배사"
            value={selected.text}
            options={options}
            onChange={(value) => {
              setSelected(value as any);
            }}
            placeholder="택배사 선택"
            className="line"
            borderBottom="0"
            borderRadius="10px 10px 0 0"
          />
          <Input
            label="운송장 번호"
            placeholder="운송장 번호 입력"
            borderRadius="0 0 10px 10px"
            value={number}
            onChange={handleChangeNumber}
          />
        </div>

        <div className="footer">
          <Button onClick={handleSubmit} disabled={!isValid}>
            완료
          </Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;

  padding: 0 20px;

  & h1 {
    font-family: Noto Sans KR;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.6000000238418579px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 15px 20px;
  }
`;

export default WaybillModal;
