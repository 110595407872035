import React from "react";
import Preview from "../../pages/Preview";
import { RouteType } from "../../types/types";

import TwoButtonModal from "../modal/TwoButtonModal";
import TermModal from "../modal/TermModal ";
import AlertModal from "../modal/AlertModal ";
import ToastModal from "../../pages/ToastModal";
import SelectModal from "../modal/SelectModal";
import PictureDetailModal from "../modal/PictureDetailModal";
import ReportModal from "../modal/ReportModal";
import { useRecoilValue } from "recoil";
import {
  categoryModalState,
  confirmModalState,
} from "../../recoil/atoms/modal";
import CategoriesModal from "../modal/CategoriesModal";
import SupportersModal from "../modal/SupportersModal";
import BaesCore from "../../containers/base/BaesCore";
import ConfirmModal from "../modal/ConfirmModal";

const WebRoute = ({ element, ...props }: RouteType) => {
  const categoriesModal = useRecoilValue(categoryModalState);
  const confirmModal = useRecoilValue(confirmModalState);
  return (
    <Preview>
      {element}
      {categoriesModal !== null && <CategoriesModal />}
      {confirmModal !== null && <ConfirmModal />}

      <BaesCore />
      <AlertModal />
      <TwoButtonModal />
      <TermModal />
      <ToastModal />
      <SelectModal />
      <ReportModal />
      <SupportersModal />

      <PictureDetailModal />
    </Preview>
  );
};

export default WebRoute;
