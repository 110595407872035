import useSWR from "swr";

function useOrder(orderId: string) {
  const { data, isLoading } = useSWR(
    `/order/detail?order_id=${orderId}&is_payment=1`
  );
  return { data, isLoading };
}

export default useOrder;
