import { api } from "./api";

export const fetcher = async (url: string) => {
  try {
    const res = await api(url);

    if (res === null) return;
    if (!res.data.success) throw new Error(res.data?.alert || "ERROR");
    return res?.data?.data || res.data;
  } catch (e: any) {
    const message = e.message;
    window.location.href = `/error?message=${message}&url=${url}`;
  }
};

const swrConfig = {
  fetcher,
};

export default swrConfig;
