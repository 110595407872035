import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { buyDigitalPackModalState } from "../../../recoil/atoms/modal";
import { getThousandCommaPrice } from "../../../utils/utils";
import SummaryCardImage from "../../card/SummaryCardImage";
import CountComponent from "../../common/CountComponent";
import ModalHeader from "../../common/ModalHeader";
import Button from "../../elements/Button";
import {
  CardLabel,
  CardPrice,
  CommonBottomModalContainer,
} from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import { alertModalState } from "../../../utils/atom";

function BuyDigitalPackModal() {
  const modal = useRecoilValue(buyDigitalPackModalState);
  const reset = useResetRecoilState(buyDigitalPackModalState);
  const setAlertModal = useSetRecoilState(alertModalState);
  const navigate = useNavigate();

  const [count, setCount] = useState(1);

  const handlePlus = () => {
    setCount((count) => count + 1);
  };

  const handleMinus = () => {
    setCount((count) => count - 1);
  };

  const handleBuy = () => {
    if (!modal) return;
    if (modal?.userId && modal?.userId === "my") {
      setAlertModal({
        title: "상품 구매 불가",
        text: "자신의 상품은 구매할 수 없습니다.",
      });
      return;
    }

    localStorage.setItem(
      "state",
      JSON.stringify({
        cardId: modal.card.id,
        amount: count,
        type: "digital-pack",
      })
    );

    navigate("/payment");
    reset();
  };

    const [origin, setOrigin] = useState<string>("");

    useEffect(() => {
        if (modal) {
            setOrigin(location.pathname);
        }
    }, [modal]);

    useEffect(() => {
        if (origin !== "" && location.pathname !== origin) {
            reset();
        }
    }, [location.pathname]);

    if (!modal) return null;
  return (
    <ModalWrapper position="bottom">
      <Wrapper>
        <ModalHeader title="카드팩 구매" />

        <div className="mt-10">
          <SummaryCardImage
            imageUrl={modal?.card?.image_url || ""}
            size="full"
          />
        </div>

        <div className="content-wrapper mt-20">
          <div>
            <CardLabel>상품 금액</CardLabel>
            <CardPrice className="mt-6">
              {getThousandCommaPrice(modal.card.price)}원
            </CardPrice>
          </div>
          <CountComponent
            count={count}
            onMinus={handleMinus}
            onPlus={handlePlus}
          />
        </div>

        <div className="footer mt-30">
          <Button styleType="line">장바구니</Button>
          <Button onClick={handleBuy}>구매하기</Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  & .content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  & .footer {
    display: flex;
    padding: 15px 0;
    gap: 8px;
  }
`;

export default BuyDigitalPackModal;
