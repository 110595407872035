import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { buyDigitalModalState } from "../../../recoil/atoms/modal";
import { getThousandCommaPrice } from "../../../utils/utils";
import SummaryCardImage from "../../card/SummaryCardImage";
import ModalHeader from "../../common/ModalHeader";
import Button from "../../elements/Button";
import {
  CardLabel,
  CardPrice,
  CommonBottomModalContainer, FixedBottomWrapper,
} from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import { alertModalState } from "../../../utils/atom";
import CardInfo from "../../card/CardInfo";

function BuyDigitalModal() {
  const modal = useRecoilValue(buyDigitalModalState);
  const reset = useResetRecoilState(buyDigitalModalState);
  const setAlertModal = useSetRecoilState(alertModalState);
  const navigate = useNavigate();

  const handleBuy = () => {
    if (!modal) return;
    if (modal?.userId && modal?.userId === "my") {
      setAlertModal({
        title: "상품 구매 불가",
        text: "자신의 상품은 구매할 수 없습니다.",
      });
      return;
    }

    localStorage.setItem(
      "state",
      JSON.stringify({
        stockIds: [modal.card.id],
        amounts: {
          [modal.card.id]: modal.card.amount,
        },
        type: "digital",
      })
    );

    navigate("/payment");

    reset();
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      reset();
    }
  }, [location.pathname]);

  if (!modal) return null;

  return (
    <ModalWrapper position="bottom" onClose={reset}>
      <Wrapper>
        <ModalHeader title="디지털 카드 구매" onClose={reset} />

        <div className="mt-10">
          <SummaryCardImage
            imageUrl={modal?.card?.image_url ?? ""}
            size="full"
          />
        </div>

        <CardInfo
            className="card-info"
            price={modal.card.price}
            contents={modal.card.etc}
            grading={String(modal.card.card_grading)}
            level={String(modal.card.card_level)}
            categoryName={modal.card.category_name}
            name={modal.card.name}
            subName={modal.card.sub_name}
        />

{/*        <div className="mt-20">
          <CardLabel>상품금액</CardLabel>
          <CardPrice>
            {getThousandCommaPrice(modal?.card?.price || 0)}원
          </CardPrice>
        </div>*/}

        <FixedBottomWrapper>
          <div className="footer">
            <Button onClick={handleBuy}>구매하기</Button>
          </div>
        </FixedBottomWrapper>
{/*
        <div className="footer mt-30">
          <Button onClick={handleBuy}>구매하기</Button>
        </div>*/}
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  position: relative;
  height: 90%;
  overflow-y: auto;

  & .footer {
    display: flex;
    gap: 8px;
    padding: 15px 20px;
  }
  
/*  & .footer {
    padding: 15px 0;
  }*/

  & .card-info {
    position: absolute;
    padding-bottom: 100px;
  }
`;

export default BuyDigitalModal;
