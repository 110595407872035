import React from "react";
import styled from "styled-components";
import {Dot} from "../../utils/CommonFunction";
import { ReactComponent as CoinGreen } from "../../assets/icons/coin-green-icon.svg";

interface DigitalCardProps {
  imageUrl: string;
  isRare: boolean;
  cardName?: string;
  paybackPoint?: number;
}

function DigitalCard({ imageUrl, isRare, cardName, paybackPoint}: DigitalCardProps) {
  return (
      <Wrapper>
        <img className={isRare ? "rare" : ""} src={imageUrl} alt="digital_card"/>
        {cardName &&
            <>
            <div>
                {paybackPoint &&
                <Info>
                    <CoinWrapper><CoinGreen/>&nbsp;<span>{Dot(paybackPoint)}</span></CoinWrapper><br/>
                </Info>
                }
                <span>{cardName}</span>
            </div>
            </>
        }
      </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  aspect-ratio: calc(357.4 / 500.41);

  & > div {
    margin-top: 23px;  
  }
  
  & > div > span {
    text-align: left;
    width: 82%;
    position: absolute;
    //margin-top: 0px;
    left: calc(8.5%);
    //top: 325px;
    z-index: 2;
    padding: 8px 24px;
    border-radius: 10px;
    //background: rgba(0, 0, 0, 0.8);

    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
    color: ${(props) => props.theme.color.white};
  }
  
  & img {
    &.rare {
      border-radius: 8px;
      background-size: contain;
      background-image: linear-gradient(
          var(--rotate)
          , #EAEAEA, gray 43%, white);
      animation: spin 2s ease-in Infinite;
    }
    width: 70% !important;
    height: 69% !important;
    object-fit: cover;
    border-radius: inherit;
  }

  @keyframes rare {
    0% {
      box-shadow: 0px 0px 30px rgba(212, 246, 72, 1);
    }
    50% {
      box-shadow: 0px 0px 15px rgba(212, 246, 72, 0.5);
    }
    to {
      box-shadow: 0px 0px 30px rgba(212, 246, 72, 1);
    }
  }

  @keyframes spin {
    0% {
      box-shadow: 0px 0px 40px rgba(234, 234, 234, 1);
    }
    100% {
      box-shadow: 0px 0px 40px rgba(245, 235, 157, 1);
    }
    
    0% {
      --rotate: 0deg;
    }
    100%{
      --rotate: 360deg;
    }
  }
  
  @property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
  }
  
  
  &.rare {
    border-radius: 8px;
    background-image: linear-gradient(
        var(--rotate)
        , #EAEAEA, gray 43%, white);
    animation: spin 2s ease-in Infinite;
  }
`;

const CoinWrapper = styled.div`
        float: left;
        margin-top: 2px;
        margin-bottom: 8px;
        //position: absolute;
        border: 1px solid white;
        border-radius: 5px;
        text-align: center;
        line-height: 17px;
        //bottom: -36px;
        width: 75px;
        min-width: 20px;
        height: 25px;
        background-color: black;
        & > span {
            color: white;
            font-size: 13px;
            font-weight: 400;
            vertical-align: middle;
        }

        & > svg {
            vertical-align: middle;
        }
`;

const Info = styled.div`
  margin: 20px 0 12px 43px;
  
  & p {
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    color: #ffffff;
    &.rare {
      opacity: 0.6;
    }
    &.name {
      margin-top: 4px;
      font-weight: 700;
      line-height: 20px;
    }
  }
`;
export default DigitalCard;
