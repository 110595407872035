import React, { useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { saleKindModalState, saleModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { useNavigate } from "react-router-dom";
import { PickListType } from "../../types/api";
import Button from "../elements/Button";
import CardList from "../list/CardList";

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";
import { ReactComponent as Search } from "../../assets/icons/search-icon.svg";
import { ReactComponent as Remove } from "../../assets/icons/input-remove-icon.svg";
import { ReactComponent as Float } from "../../assets/icons/float2-icon.svg";
import { ReactComponent as EmptyIcon } from "../../assets/icons/404-icon.svg";
import useCommonList from "../../hooks/useCommonList";
import Target from "../common/Target";
import { toggleLike } from "../../utils/utils";

const SaleModal = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useRecoilState(saleModalState);
  const setSaleKindModal = useSetRecoilState(saleKindModalState);

  const wrapperRef = useRef(null);
  const content = useRef(null);

  const [search, setSearch] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [pack, setPack] = useState<0 | 1>(0);
  const [index, setIndex] = useState<any>([]);

  const { data, mutate, target } = useCommonList({
    url: "card",
    params: {
      is_pack: pack,
      type: "goods",
      order: "abc",
      search: result,
    },
    key: "cards",
  });

  const listData: PickListType[] = useMemo(
    () => data?.cards?.data,
    [data?.cards?.data]
  );

  const handleLike = (id: number) => {
    toggleLike({
      id,
      mutate,
      key: "cards",
    });
  };

  const handleRequestNewCard = () => {
    navigate("/mypage/newCard");
    setModal(null);
  };

  useEffect(() => {
    if (wrapperRef.current !== null && data) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.display = "block";
        setTimeout(() => {
          //@ts-ignore
          wrapperRef.current.style.opacity = "1";
        });
      });
    }
  }, [wrapperRef, data, modal]);

  const handleClose = () => {
    setTimeout(() => {
      //@ts-ignore
      wrapperRef.current.style.opacity = "0";
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.display = "none";

        modal?.onClose && modal.onClose();
        setModal(null);
      }, 300);
    });
  };

  const handleCheck = (item: PickListType) => {
    let arr = [...index];
    // @ts-ignore
    if (index.includes(item)) {
      // @ts-ignore
      arr.splice(index.indexOf(item), 1);
    } else {
      // @ts-ignore
      arr.push(item);
    }
    setIndex(arr);
  };

  const handleConfirm = () => {
    setTimeout(() => {
      //@ts-ignore
      wrapperRef.current.style.opacity = "0";
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.display = "none";

        modal?.onConfirm && modal.onConfirm(index, pack);
        setModal(null);
      }, 300);
    });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setResult(search);
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="none" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <Header>
          판매 상품 선택
          <Close
            onClick={() => {
              modal?.first && setSaleKindModal({ visiable: true });
              handleClose();
            }}
          />
        </Header>
        <SearchArea>
          <div>
            <SearchIcon onClick={() => setResult(search)} />
            <SearchForm onSubmit={handleSubmit}>
              <SearchInput
                type="text"
                placeholder="카드명 또는 모델명으로 검색"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </SearchForm>
            {search !== "" && (
              <RemoveIcon
                onClick={() => {
                  setSearch("");
                  setResult("");
                }}
              />
            )}
          </div>
        </SearchArea>
        <TabArea>
          <button
            type="button"
            className={pack === 0 ? "active" : ""}
            onClick={() => {
              setPack(0);
              setIndex([]);
            }}
          >
            카드
          </button>
          <button
            type="button"
            className={pack === 1 ? "active" : ""}
            onClick={() => {
              setPack(1);
              setIndex([]);
            }}
          >
            카드팩
          </button>
        </TabArea>
        {listData?.length > 0 ? (
          <Contents ref={content}>
            {listData?.map((item, i) => (
              <CardList
                key={"sale_card_list_" + i}
                id={item.id}
                image={item.image_url}
                my_like={item.my_like}
                title={item.name}
                price={item.price}
                onClick={() => handleCheck(item)}
                onLike={handleLike}
                check={true}
                checked={index.includes(item) ? true : false}
              />
            ))}
            <Target target={target} />
          </Contents>
        ) : (
          <Empty>
            <EmptyIcon />
            <EmptyText>
              <p>일치하는 검색 결과가 없습니다.</p>
              <h1>찾고 있는 상품이 없나요?</h1>
              <Button styleType="line" onClick={handleRequestNewCard}>
                상품 등록 요청하기
              </Button>
            </EmptyText>
          </Empty>
        )}
        {listData?.length > 0 && (
          <FloatButton
            onClick={() => {
              if (content.current !== null) {
                //@ts-ignore
                content.current.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          />
        )}
        <ButtonBox>
          <Button
            type="button"
            onClick={() => {
              handleConfirm();
            }}
            disabled={index.length === 0}
          >
            {index.length}개 선택 완료
          </Button>
        </ButtonBox>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SaleModal;
const ButtonBox = styled.div`
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const EmptyText = styled.div`
  text-align: center;

  & > p {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    color: ${(props) => props.theme.color.gray6};
  }

  & > h1 {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray2};
    margin-bottom: 17px;
  }

  & > button {
    width: 200px;
  }
`;

const Empty = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    width: 46px;
    height: 46px;
    margin-bottom: 16px;
  }
`;

const Contents = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: calc(100% - 158px);
  overflow: auto;
`;

const FloatButton = styled(Float)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 2;
  width: 60px;
  height: 60px;
`;

const TabArea = styled.div`
  display: flex;
  align-items: center;

  & > button {
    width: 100%;
    padding: 13px 0;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray4};
    border-bottom: solid 1px ${(props) => props.theme.color.gray7};

    &.active {
      color: ${(props) => props.theme.color.gray1};
      font-weight: 700;
      border-color: ${(props) => props.theme.color.gray1};
    }
  }
`;

const RemoveIcon = styled(Remove)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: 10px;
`;

export const SearchForm = styled.form`
  width: 100%;
`;

const SearchInput = styled.input`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.2px;
  background: none;
  color: ${(props) => props.theme.color.gray1};

  &::placeholder {
    color: ${(props) => props.theme.color.gray5};
  }
`;

const SearchIcon = styled(Search)`
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const SearchArea = styled.div`
  padding: 9px 20px;

  & > div {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: ${(props) => props.theme.color.gray8};
  }
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 0;
  transition: all 0.3s;
  display: none;
  opacity: 0;
`;
